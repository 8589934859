import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetDeleteReportTag } from '../common/WidgetTagsAuth'

interface Props {
  reportId: number
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function DeleteMessageReportModal(props: Props) {
  const { reportId, open, handleClose } = props
  const [disableButtons, setDisableButtons] = useState(false)

  const deleteReport = async () => {
    setDisableButtons(true)

    await fetchAdmin({
      path: `message-reports/${reportId}`,
      method: 'DELETE',
    })

    handleClose(true)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteReportTag}>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>
          {`Delete report #${reportId}?`}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            Once removed, you can&apos;t undo this operations.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button disabled={disableButtons} onClick={deleteReport} color="error">Remove</Button>
          <Button disabled={disableButtons} onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default DeleteMessageReportModal
