import { Button } from '@mui/material'
import { MRT_ColumnDef as MrtColumnDef } from 'material-react-table'
import { useNavigate } from 'react-router-dom'
import { useGetCampaignRewards } from '../../api/query/campaign/rewards'
import { FilterParams } from '../../api/query/params'
import { DateCell } from '../app/Timezone'
import CustomDateFilter from '../v2/common/date/CustomDateFilter'
import MrtDataGrid from '../v2/common/MrtDataGrid'
import { useTableSearchParams } from '../v2/hooks/useTableSearchParams'

interface Reward {
  id: string
  quickbloxUserId: string
  amount: string
  confirmedAt: string
  createdAt: string
  updatedAt: string
}

function Rewards() {
  const navigate = useNavigate()
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    dateFilter,
    setDateFilter,
    queryParams,
    clearFilters,
  } = useTableSearchParams({
    defaultInit: {
      sortField: 'id',
      sortDir: 'desc',
    },
  })

  const columns = getColumns({
    dateFilter,
    setDateFilter,
  })

  const {
    data, isError, isLoading, refetch,
  } = useGetCampaignRewards(queryParams)

  return (
    <MrtDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows || 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      onRowDoubleClick={(row) => navigate(`/campaign/rewards/${row.id}`)}
      topToolbarContent={(
        <Button
          variant="contained"
          color="info"
          size="large"
          onClick={() => navigate('/campaign/rewards/create')}
        >
          create
        </Button>
      )}
    />
  )
}

function getColumns({
  dateFilter,
  setDateFilter,
}: {
  dateFilter: FilterParams
  setDateFilter: (filter: FilterParams) => void
}): MrtColumnDef<Reward>[] {
  return [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 100,
    },
    {
      accessorKey: 'quickbloxUserId',
      header: 'User ID',
      size: 150,
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      size: 150,
    },
    {
      accessorKey: 'confirmedAt',
      header: 'Confirmed At',
      size: 200,
      Cell: DateCell,
      Filter: () => CustomDateFilter({
        field: 'confirmedAt',
        filter: dateFilter,
        setFilter: setDateFilter,
      }),
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 200,
      Cell: DateCell,
      Filter: () => CustomDateFilter({
        field: 'createdAt',
        filter: dateFilter,
        setFilter: setDateFilter,
      }),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      size: 200,
      Cell: DateCell,
      Filter: () => CustomDateFilter({
        field: 'updatedAt',
        filter: dateFilter,
        setFilter: setDateFilter,
      }),
    },
  ]
}

export default Rewards
