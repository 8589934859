import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetDeleteMomentTag } from '../common/WidgetTagsAuth'

interface Props {
  identifier: string
  commentId: number
  commentType: string
  comment: string
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function DeleteMomentCommentModal(props: Props) {
  const {
    identifier, commentId, commentType, comment, open, handleClose,
  } = props

  const deleteComment = async () => {
    await fetchAdmin({
      path: `moments/${identifier}/comment/${commentId}`,
      method: 'DELETE',
    })
    handleClose(true)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteMomentTag}>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>
          {`Delete a comment from moment, ${identifier}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Comment Info
            {' '}
            <br />
            ID:
            {' '}
            {commentId}
            {' '}
            <br />
            type:
            {' '}
            {commentType}
            {' '}
            <br />
            message:
            {' '}
            {comment}
            {' '}
            <br />
          </DialogContentText>
          <DialogContentText>
            Once deleted, you can&apos;t undo this operation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteComment} color="error">Remove</Button>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default DeleteMomentCommentModal
