import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {
  Box,
} from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import KeyIcon from '@mui/icons-material/Key'
import UpdateRoleAdminUserModal from './UpdateRoleAdminUserModal'
import UpdatePasswordAdminUserModal from './UpdatePasswordAdminUserModal'
import DeleteAdminUserModal from './DeleteAdminUserModal'

interface Props {
  userName: string
  role: string
  backText: string // text for the back button
  exitUserDetails: (deletedUser: boolean) => void // handler for the back button
}

function AdminUserDetails(props: Props) {
  const {
    userName, role, backText, exitUserDetails,
  } = props
  const [details, setDetails] = useState({
    userName: '',
    role: '',
  })
  const [showUpdateRoleAdminUserModal, setShowUpdateRoleAdminUserModal] = useState(false)
  const [showUpdatePasswordAdminUserModal, setShowUpdatePasswordAdminUserModal] = useState(false)
  const [showDeleteAdminUserModal, setShowDeleteAdminUserModal] = useState(false)

  const handleCloseUpdateRoleAdminUserModal = (actionTriggered: boolean) => {
    setShowUpdateRoleAdminUserModal(false)
    window.location.reload()
  }

  const handleOpenUpdateRoleAdminUserModal = () => {
    setShowUpdateRoleAdminUserModal(true)
  }

  const handleCloseUpdatePasswordAdminUserModal = (actionTriggered: boolean) => {
    setShowUpdatePasswordAdminUserModal(false)
    window.location.reload()
  }

  const handleOpenUpdatePasswordAdminUserModal = () => {
    setShowUpdatePasswordAdminUserModal(true)
  }

  const handleCloseDeleteAdminUserModal = (actionTriggered: boolean) => {
    setShowDeleteAdminUserModal(false)
    window.location.reload()
  }

  const handleOpenDeleteAdminUserModal = () => {
    setShowDeleteAdminUserModal(true)
  }

  useEffect(() => {
    setDetails({
      userName,
      role,
    })

    return () => {
    }
  }, [userName, role])

  return (
    <>
      <Box sx={{
        width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
      }}
      >
        {backText
          && (
          <Button
            startIcon={<NavigateBeforeIcon />}
            onClick={() => exitUserDetails(false)}
            sx={{ alignSelf: 'flex-start' }}
          >
            {backText}
          </Button>
          )}
        <Stack direction="row">
          <Stack direction="column" justifyContent="center">
            <Typography variant="h4" m={0}>
              {details.userName}
            </Typography>
            <Typography variant="h5" sx={{ color: 'text.secondary' }}>
              {details.role}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} m={1}>
          <Button
            onClick={handleOpenUpdateRoleAdminUserModal}
            variant="outlined"
            color="info"
            startIcon={<TrendingUpIcon />}
          >
            Change Role
          </Button>
          <Button
            onClick={handleOpenUpdatePasswordAdminUserModal}
            variant="outlined"
            color="info"
            startIcon={<KeyIcon />}
          >
            Change Password
          </Button>
          <Button
            onClick={handleOpenDeleteAdminUserModal}
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
          >
            Delete User
          </Button>
        </Stack>
        <UpdateRoleAdminUserModal
          open={showUpdateRoleAdminUserModal}
          userName={userName}
          handleClose={handleCloseUpdateRoleAdminUserModal}
        />
        <UpdatePasswordAdminUserModal
          open={showUpdatePasswordAdminUserModal}
          userName={userName}
          handleClose={handleCloseUpdatePasswordAdminUserModal}
        />
        <DeleteAdminUserModal
          open={showDeleteAdminUserModal}
          userName={userName}
          handleClose={handleCloseDeleteAdminUserModal}
        />
      </Box>
    </>
  )
}

export default AdminUserDetails
