import Input from '@mui/material/Input';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import WidgetTagsAuth, {
  expectedWidgetUpdateContentModerationTag,
  expectedWidgetDeleteContentModerationTag,
} from '../common/WidgetTagsAuth';
import { useGetTextFilters, useAddTextFilter, useDeleteTextFilters } from '../../api/query/textfilters';

interface TextFilterProps {
  id: number;
  text: string;
}

function TextFilter() {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [inputText, setInputText] = useState('');

  const {
    data: textFilters, isLoading, isError, refetch: refetchTextFilter,
  } = useGetTextFilters();
  const addTextFilterMutation = useAddTextFilter();
  const deleteTextFiltersMutation = useDeleteTextFilters();

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    if (isError) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            Error loading text filters
          </TableCell>
        </TableRow>
      );
    }

    return textFilters?.rows?.map((filter: TextFilterProps) => (
      <TableRow key={filter.id}>
        <TableCell>
          <Checkbox
            checked={selectedIds.includes(filter.id)}
            onChange={() => handleCheckboxChange(filter.id)}
          />
        </TableCell>
        <TableCell>{filter.text}</TableCell>
      </TableRow>
    ));
  };

  const handleCheckboxChange = (id: number) => {
    setSelectedIds((prev) => (prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]));
  };

  const handleSubmitButton = async () => {
    if (!inputText) return;

    try {
      await addTextFilterMutation.mutateAsync(inputText);
      setInputText('');
      refetchTextFilter();
    } catch (error) {
      console.error('Failed to add text filter:', error); // eslint-disable-line no-console
    }
  };

  const handleDeleteButton = async () => {
    if (selectedIds.length === 0) return;

    try {
      await deleteTextFiltersMutation.mutateAsync(selectedIds);
      setSelectedIds([]);
      refetchTextFilter();
    } catch (error) {
      console.error('Failed to delete text filters:', error); // eslint-disable-line no-console
    }
  };

  const selectedTexts = textFilters
    ?.rows.filter((filter: TextFilterProps) => selectedIds.includes(filter.id))
    .map((filter: TextFilterProps) => filter.text)
    .join(', ');

  return (
    <Card sx={{
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 110px)',
      overflow: 'hidden',
    }}
    >
      <Box sx={{ padding: 2 }}>
        <Stack spacing={2} direction="row" sx={{ mb: 2, flexShrink: 0 }}>
          <WidgetTagsAuth
            expectedWidgetTag={expectedWidgetUpdateContentModerationTag}
          >
            <Input
              id="text-filter"
              type="text"
              placeholder="Add filter words separated by comma"
              sx={{ flexGrow: 1 }}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />
            <Button
              variant="outlined"
              color="success"
              onClick={handleSubmitButton}
              disabled={addTextFilterMutation.isLoading || isLoading}
            >
              {addTextFilterMutation.isLoading ? 'Adding...' : 'Add'}
            </Button>
          </WidgetTagsAuth>
          <WidgetTagsAuth
            expectedWidgetTag={expectedWidgetDeleteContentModerationTag}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleDeleteButton}
              disabled={deleteTextFiltersMutation.isLoading || isLoading}
            >
              {deleteTextFiltersMutation.isLoading ? 'Deleting...' : 'Delete'}
            </Button>
          </WidgetTagsAuth>
        </Stack>
        <Typography>
          Selected Texts: &nbsp;
          {selectedTexts}
        </Typography>
      </Box>
      <Box sx={{ height: '100%' }}>
        <CardContent sx={{ height: '100%', overflow: 'auto' }}>
          <TableContainer>
            <Table padding="none" stickyHeader>
              <TableBody>
                {renderTableContent()}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Box>
    </Card>
  );
}

export default TextFilter;
