import React from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function UserProfileLink({ userId }: { userId: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {userId}
      <Link to={`/users/${userId}`} target="_blank">
        <OpenInNewIcon fontSize="small" />
      </Link>
    </Box>
  )
}

export default UserProfileLink
