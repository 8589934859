import secureLocalStorage from 'react-secure-storage'

export function setStorage(key: string, value: string | number | boolean | object) {
  secureLocalStorage.setItem(key, value)
}

export function getStorage<T extends string | number | boolean | object | null>(key: string, defaultValue?: T): T {
  return (
    secureLocalStorage.getItem(key)
      ?? (defaultValue ?? null)
  ) as T
}
