import { useEffect, useState } from 'react'
import { EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import WeeklyLikeMomentDetails from './WeeklyLikeMomentDetails'
import WeeklyLikeMomentsGrid from './WeeklyLikeMomentsGrid'

function WeeklyLikeMoments() {
  const [showGrid, setShowGrid] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [weeklyLikeMomentId, setWeeklyLikeMomentId] = useState<number>()
  const showDetails = (e: CustomEvent) => {
    setShowGrid(false)
    setWeeklyLikeMomentId(e.detail.id)
  }
  const exitDetails = () => {
    setShowGrid(true)
    setRefresh((r) => !r)
    setWeeklyLikeMomentId(undefined)
  }

  useEffect(() => {
    eventBus.on(EVENT.SHOW_DETAILS, showDetails)

    return () => {
      eventBus.remove(EVENT.SHOW_DETAILS, showDetails)
    }
  }, [])

  return (
    <>
      <WeeklyLikeMomentsGrid
        show={showGrid}
        refresh={refresh}
      />

      {
        weeklyLikeMomentId !== undefined && (
          <WeeklyLikeMomentDetails
            weeklyLikeMomentId={weeklyLikeMomentId}
            exitDetails={exitDetails}
          />
        )
      }
    </>
  )
}

export default WeeklyLikeMoments
