import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetUpdateReportTag } from '../common/WidgetTagsAuth'

interface Props {
  momentIdentifier: string
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function ResolveAllMomentReportsModal(props: Props) {
  const { momentIdentifier, open, handleClose } = props
  const [disableButtons, setDisableButtons] = useState(false)

  const resolveReports = async () => {
    setDisableButtons(true)

    await fetchAdmin({
      path: `moment-reports/resolveAll/${momentIdentifier}`,
      method: 'PUT',
    })
    handleClose(true)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateReportTag}>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>
          Resolve all reports associated with this moment?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This operation will mark all reports associated with this moment as resolved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={disableButtons} onClick={resolveReports} color="error">Resolve All</Button>
          <Button disabled={disableButtons} onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default ResolveAllMomentReportsModal
