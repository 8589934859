import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { TextField } from '@mui/material'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  description: string
  quickbloxDialogId: string
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function EditDialogDescriptionModal(props: Props) {
  const {
    description, quickbloxDialogId, open, handleClose,
  } = props

  const [newDescription, setNewDescription] = React.useState(' ')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewDescription(event.target.value)
  }

  const deleteDialogDescription = async () => {
    await fetchAdmin({
      path: `dialogs/group/${quickbloxDialogId}`,
      method: 'PUT',
      body: {
        description: newDescription,
      },
    })
    handleClose(true)
  }

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>
        Update the dialog description.
      </DialogTitle>
      <DialogContent>
        <DialogContentText />
        <TextField
          autoFocus
          margin="dense"
          label="Dialog description"
          defaultValue={description}
          multiline
          fullWidth
          variant="standard"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button disabled={newDescription === ' '} onClick={deleteDialogDescription} color="info">Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialogDescriptionModal
