import React from 'react'
import {
  MenuItem, Select, SelectChangeEvent, Stack,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { FilterParams } from '../../../../api/query/params'

type CustomDateFilterProps = {
  field: string;
  filter: FilterParams;
  setFilter: (filter: FilterParams) => void;
}

function CustomDateFilter({ field, filter, setFilter }: CustomDateFilterProps) {
  const handleSelectChange = (event: SelectChangeEvent) => {
    setFilter({ ...filter, filterOp: event.target.value as string, filterField: field });
  }

  const handleDateChange = (date: DateTime | null) => {
    if (date) {
      const formattedDate = date.toFormat("yyyy-MM-dd'T'HH:mm");
      setFilter({ ...filter, filterValue: formattedDate, filterField: field });
    }
  }

  const isActive = filter.filterField === field;

  const getDatePickerValue = () => {
    if (!isActive) return null;
    if (!filter.filterValue) return null;
    return DateTime.fromISO(filter.filterValue);
  };

  return (
    <Stack direction="row" spacing={2}>
      <Select
        value={isActive ? filter.filterOp : ''}
        onChange={handleSelectChange}
        variant="standard"
        displayEmpty
      >
        <MenuItem value="before">before</MenuItem>
        <MenuItem value="after">after</MenuItem>
      </Select>
      <DatePicker
        value={getDatePickerValue()}
        onChange={handleDateChange}
        slotProps={{ textField: { variant: 'standard' } }}
        openTo="year"
        disableFuture
      />
    </Stack>
  )
}

export default CustomDateFilter
