import React, {
  useCallback, useEffect, useState,
} from 'react'
import {
  Card, CardContent, CardMedia, CircularProgress, Dialog, DialogContent, Typography,
} from '@mui/material'
import { buildStorageApiUrl } from '../../services/urlBuilder'
import { decryption } from '../../services/encryption'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  userId: number
  attachment: Attachment
  dialogId: string
}

interface Status {
  loading: boolean
  image?: string
  audio?: string
  video?: {
    url: string
    width: number
    height: number
  }
  error?: string
}

interface Attachment {
  id: string
  name: string
  size: string
  type: string
  'content-type': string
  width?: string
  height?: string
}

function AttachmentDetailsCard({ userId, attachment, dialogId }: Props) {
  const [status, setStatus] = useState<Status>({ loading: true })
  const [open, setOpen] = useState(false)

  const updateStatus = (newStatus: Partial<Status>) => {
    setStatus((prev) => ({ ...prev, ...newStatus }))
  }

  const loadAttachment = useCallback(async () => {
    try {
      if (attachment) {
        const path = `quickblox/contents/${userId}/${attachment.id}`

        switch (attachment.type) {
          case 'image': {
            const imageResponse = await fetchAdmin({
              path,
              method: 'GET',
            })
            if (imageResponse.status === 500) {
              updateStatus({ loading: false, error: 'Image not available' });
              return;
            }
            const data = await imageResponse.arrayBuffer()
            if (data.byteLength === 0) {
              throw new Error('Empty data received');
            }

            let decryptedData: string;
            try {
              decryptedData = decryption({ dialogId, data });
            } catch (error) {
              console.error('Decryption error:', error); // eslint-disable-line no-console
              updateStatus({ loading: false, error: 'Image decryption failed' });
              return;
            }

            const base64Data = `data:${attachment['content-type']};base64,${decryptedData}`;
            // const imageUrl = `data:${attachment['content-type']};base64,${decryption({ dialogId, data: imageData })}`
            updateStatus({ loading: false, image: base64Data });
            break
          }

          case 'video': {
            const videoResponse = await fetchAdmin({
              path,
              method: 'GET',
            })
            if (videoResponse.status === 500) {
              updateStatus({ loading: false, error: 'Video not available' });
              return;
            }
            const data = await videoResponse.arrayBuffer()
            if (data.byteLength === 0) {
              throw new Error('Empty data received');
            }
            const videoBlob = new Blob([data])
            updateStatus({
              loading: false,
              video: {
                url: URL.createObjectURL(videoBlob),
                width: parseInt(attachment.width ?? '0'),
                height: parseInt(attachment.height ?? '0'),
              },
            })
            break
          }

          case 'audio': {
            const audioResponse = await fetch(buildStorageApiUrl(path))
            if (audioResponse.status === 500) {
              updateStatus({ loading: false, error: 'Audio not available' });
              return;
            }
            const audioData = await audioResponse.arrayBuffer()
            const audioUrl = `data:${attachment['content-type']};base64,${decryption({ dialogId, data: audioData })}`
            updateStatus({ loading: false, audio: audioUrl })
            break
          }

          default:
            updateStatus({ loading: false, error: 'Unsupported type' })
            break
        }
      } else {
        updateStatus({ loading: false, error: 'No attachment' })
      }
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
      updateStatus({ loading: false, error: 'Failed to load' })
    }
  }, [attachment, dialogId, userId])

  useEffect(() => {
    loadAttachment()
  }, [loadAttachment])

  const closeDialog = () => setOpen(false)

  return (
    <>
      <Card>
        <CardContent>
          {/* <Typography variant="h4">Attachment Details</Typography> */}
          {status.loading && <CircularProgress />}
          {status.error && <Typography variant="body2">{status.error}</Typography>}
        </CardContent>

        {!status.loading && !status.error && (
          <>
            {status.image && (
              <CardContent sx={{ padding: '0.5em' }}>
                {/* <Typography variant="h5" gutterBottom>Image</Typography> */}
                <CardMedia
                  component="img"
                  image={status.image}
                  sx={{ width: '100%', height: '140px', objectFit: 'contain' }}
                  onClick={() => setOpen(true)}
                />
              </CardContent>
            )}
            {status.audio && (
              <CardContent>
                {/* <Typography variant="h5" gutterBottom>Audio</Typography> */}
                <CardMedia
                  component="audio"
                  controls
                  src={status.audio}
                />
              </CardContent>
            )}
            {status.video && (
              <CardContent sx={{ padding: '0.5em' }}>
                <CardMedia
                  component="video"
                  controls
                  src={status.video.url}
                  sx={{
                    width: '100%',
                    maxHeight: '150px',
                    objectFit: 'contain',
                  }}
                />
              </CardContent>
            )}
          </>
        )}
      </Card>

      {status.image && (
        <Dialog
          open={open}
          onClose={closeDialog}
          maxWidth="xl"
          PaperProps={{
            style: {
              width: 'auto',
              height: 'auto',
              maxWidth: '95vh',
              maxHeight: '95vh',
              margin: 0,
              padding: '10px',
              // overflow: 'auto',
            },
          }}
        >
          <DialogContent
            sx={{
              padding: 0,
              margin: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              // overflow: '',
            }}
          >
            <img
              alt="attachment"
              src={status.image}
              style={{
                maxHeight: '90vh',
                maxWidth: '100%',
                width: '100%',
                height: '100%',
                objectFit: 'scale-down',
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default AttachmentDetailsCard
