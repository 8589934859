import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  taskId: number
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function DeleteMaintenanceTaskModal(props: Props) {
  const { taskId, open, handleClose } = props
  const [disableButtons, setDisableButtons] = useState(false)

  const deleteTask = async () => {
    setDisableButtons(true)

    await fetchAdmin({
      path: `maintenance/${taskId}`,
      method: 'DELETE',
    })

    handleClose(true)
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>
        {`Delete task #${taskId}?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Once removed, you can&apos;t undo this operation.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={disableButtons} onClick={deleteTask} color="error">Remove</Button>
        <Button disabled={disableButtons} onClick={() => handleClose(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteMaintenanceTaskModal
