import { Delete } from '@mui/icons-material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {
  Box, Button, Card, CardActions, CardContent, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import eventBus from '../../services/eventBus'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetDeleteReportTag } from '../common/WidgetTagsAuth'
import UserDetails from '../user/UserDetails'
import DeleteUserReportModal from './DeleteUserReportModal'
import ResolveAllUserReportsModal from './ResolveAllUserReportsModal'
import ResolveUserReportModal from './ResolveUserReportModal'
import { UserReportInfo } from './UserReports'
import UserProfileLink from '../user/UserProfileLink'

interface Props {
  reportInfo?: UserReportInfo
}

function UserReportDetails(props: Props) {
  const { reportInfo } = props
  const { identifier } = useParams()
  const navigate = useNavigate()

  const [details, setDetails] = useState<UserReportInfo>()
  const [showUserDetails, setShowUserDetails] = useState(false)
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false)
  const [showResolveModal, setShowResolveModal] = useState(false)
  const [showResolveAllModal, setShowResolveAllModal] = useState(false)

  function handleResolve() {
    setShowResolveModal(true)
  }

  function handleResolveAll() {
    setShowResolveAllModal(true)
  }

  function handleExitUserDetails(deletedUser: boolean) {
    setShowUserDetails(false)
    if (deletedUser) {
      onExit()
    }
  }

  function onExit() {
    if (reportInfo) {
      eventBus.dispatch('goBackToUserReports')
    } else {
      navigate('/reports/users')
    }
  }

  useEffect(() => {
    if (reportInfo) {
      setDetails(reportInfo)
    } else {
      fetchAdmin({
        path: `user-reports/${identifier}`,
        method: 'GET',
      })
        .then((res) => res.json())
        .then((json) => setDetails(json))
    }
  }, [reportInfo, identifier])

  if (details === undefined) {
    return null
  }

  return (
    <>
      {showUserDetails
        ? (
          <UserDetails
            quickbloxUserId={details.reportedQuickbloxUserId}
            backText="Report"
            exitUserDetails={handleExitUserDetails}
          />
        )
        : (
          <Box sx={{
            width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
          }}
          >
            <Button
              startIcon={<NavigateBeforeIcon />}
              onClick={onExit}
              sx={{ alignSelf: 'flex-start' }}
            >
              Reports
            </Button>

            <Stack direction="row">
              <Typography variant="h3" gutterBottom>
                {`Report #${details.id}`}
              </Typography>
            </Stack>
            <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteReportTag}>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={() => setShowDeleteReportModal(true)}
                  variant="outlined"
                  color="error"
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
              </Stack>
            </WidgetTagsAuth>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <ReportDetailsCard reportInfo={details} handleResolve={handleResolve} handleResolveAll={handleResolveAll} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ReportedUserCard onClickUserDetails={() => setShowUserDetails(true)} />
              </Grid>
            </Grid>
          </Box>
        )}

      <DeleteUserReportModal
        reportId={details.id}
        open={showDeleteReportModal}
        handleClose={(actionTriggered: boolean) => {
          setShowDeleteReportModal(false)
          if (actionTriggered) {
            onExit()
          }
        }}
      />

      <ResolveUserReportModal
        reportId={details.id}
        open={showResolveModal}
        handleClose={(actionTriggered: boolean) => {
          setShowResolveModal(false)
          if (actionTriggered) {
            onExit()
          }
        }}
      />

      <ResolveAllUserReportsModal
        reportedQuickbloxUserId={details.reportedQuickbloxUserId}
        open={showResolveAllModal}
        handleClose={(actionTriggered: boolean) => {
          setShowResolveAllModal(false)
          if (actionTriggered) {
            onExit()
          }
        }}
      />
    </>
  )
}

interface ReportDetailsCardProps {
  reportInfo: UserReportInfo
  handleResolve: () => void
  handleResolveAll: () => void
}

function ReportDetailsCard(props: ReportDetailsCardProps) {
  const { reportInfo, handleResolve, handleResolveAll } = props
  const createdAt = moment(new Date(reportInfo.createdAt)).format('MMMM Do YYYY, h:mm:ss A')
  const updatedAt = moment(new Date(reportInfo.updatedAt)).format('MMMM Do YYYY, h:mm:ss A')
  const resolvedAt = reportInfo.resolvedAt ? moment(new Date(reportInfo.resolvedAt)).format('MMMM Do YYYY, h:mm:ss A') : null

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Report Details
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Reporter QB User ID
                </TableCell>
                <TableCell>
                  <UserProfileLink userId={reportInfo.reporterQuickbloxUserId} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Reported QB User ID
                </TableCell>
                <TableCell>
                  <UserProfileLink userId={reportInfo.reportedQuickbloxUserId} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Category
                </TableCell>
                <TableCell>
                  {reportInfo.category}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Created At
                </TableCell>
                <TableCell>
                  {createdAt}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Updated At
                </TableCell>
                <TableCell>
                  {updatedAt}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Resolved At
                </TableCell>
                <TableCell>
                  {resolvedAt || 'Not Yet Resolved'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h4" gutterBottom>
          <br />
          Report Message
        </Typography>
        <Typography variant="body2">
          {reportInfo.details ? reportInfo.details : 'No message.'}
        </Typography>
      </CardContent>
      <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteReportTag}>
        <CardActions>
          <Button disabled={Boolean(reportInfo.resolvedAt)} onClick={handleResolve}>Resolve</Button>
          <Button onClick={handleResolveAll}>Resolve All</Button>
        </CardActions>
      </WidgetTagsAuth>
    </Card>
  )
}

interface ReportedUserCardProps {
  onClickUserDetails: () => void
}

function ReportedUserCard(props: ReportedUserCardProps) {
  const { onClickUserDetails } = props

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Reported User Details
        </Typography>
        <Typography variant="body2">
          Click here to view user details.
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={onClickUserDetails}>User Details</Button>
      </CardActions>
    </Card>
  )
}

export default UserReportDetails
