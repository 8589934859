import { useQuery } from '@tanstack/react-query'
import { fetchAdmin } from '../../../services/fetch'
import { QUERY_KEYS } from '../keys'
import { QueryParams } from '../params'

export function useGetCampaignRewards(params: QueryParams) {
  return useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_REWARDS, params],
    queryFn: async () => {
      const res = await fetchAdmin({
        path: 'campaign/rewards',
        method: 'GET',
        queries: { ...params },
      })

      return res.ok
        ? res.json()
        : null
    },
  })
}
