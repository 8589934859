import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

interface ConfirmModalResult {
  message?: string
  callback?: () => void
}

interface Props {
  openTrigger: any
  title: string
  message: string
  labelAccept?: string
  labelCancel?: string
  onAccept: () => Promise<ConfirmModalResult> | ConfirmModalResult | void
  onCancel?: () => Promise<ConfirmModalResult> | ConfirmModalResult | void
}

function ConfirmModal(props: Props) {
  const {
    openTrigger, title, message, labelAccept, labelCancel, onAccept, onCancel,
  } = props
  const [status, setStatus] = useState({
    open: false,
    openResult: false,
    disableButtons: false,
    resultMessage: '',
  })
  const checker = useRef(openTrigger)

  async function handleClick(callback?: () => Promise<ConfirmModalResult> | ConfirmModalResult | void) {
    let res
    setStatus((old) => ({ ...old, disableButtons: true }))

    if (callback != null) {
      res = await callback()
    }

    if (res?.message) {
      handleResult(res)
    } else {
      handleClose()

      if ((res?.callback) != null) {
        res.callback()
      }
    }
  }

  function handleClose() {
    setStatus((old) => ({
      ...old,
      open: false,
      openResult: false,
      disableButtons: true,
    }))
  }

  function handleResult(result: ConfirmModalResult) {
    setStatus((old) => ({
      ...old,
      open: false,
      openResult: true,
      resultMessage: `${result.message}`,
    }))
  }

  useEffect(() => {
    if (checker.current !== openTrigger) {
      checker.current = openTrigger
      setStatus((old) => ({
        ...old,
        open: true,
        disableButtons: false,
      }))
    }
  }, [openTrigger])

  return (
    <>
      <Dialog
        open={status.open}
        onClose={handleClose}
      >
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={status.disableButtons}
            onClick={async () => {
              await handleClick(onAccept)
            }}
            color="error"
          >
            {labelAccept ?? 'Accept'}
          </Button>
          <Button
            disabled={status.disableButtons}
            onClick={async () => {
              await handleClick(onCancel)
            }}
          >
            {labelCancel ?? 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={status.openResult}
        onClose={handleClose}
      >
        <DialogContent>
          <DialogContentText>
            {status.resultMessage}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Accept</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmModal
