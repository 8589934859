import {
  FormControl, Grid,
  InputLabel, MenuItem, Select,
} from '@mui/material'

const Explore = [
  'Popular',
  'Trending',
  'YouMayLike',
  'Featured',
]

const Regions = [
  'US',
  'JP',
  'Others',
]

export const initToolbarQueries = {
  explore: Explore[0],
  region: Regions[0],
}

function ExploreSearchToolbar(props: any) {
  const { toolbarQueries, setToolbarQueries } = props
  const updateQueries = (newQueries: object) => {
    setToolbarQueries((old: object) => ({
      ...old,
      ...newQueries,
    }))
  }

  return (
    <Grid container spacing={2} p={1}>
      <Grid item>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel id="explore-select-label">explore</InputLabel>
          <Select
            labelId="explore-select-label"
            label="explore"
            value={toolbarQueries.explore}
            onChange={(event) => updateQueries({ explore: event.target.value })}
          >
            {
              Explore.map((value) => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel id="explore-region-select-label">region</InputLabel>
          <Select
            labelId="explore-region-select-label"
            label="region"
            value={toolbarQueries.region}
            onChange={(event) => updateQueries({ region: event.target.value })}
          >
            {
              Regions.map((value) => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default ExploreSearchToolbar
