import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material'
import { useState } from 'react'
import { checkAuth } from '../../services/authentication'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetUpdateReportTag } from '../common/WidgetTagsAuth'

interface Props {
  quickbloxDialogId: string
  quickbloxMessageId: string
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function ResolveAllMessageReportsModal(props: Props) {
  const {
    quickbloxDialogId, quickbloxMessageId, open, handleClose,
  } = props
  const [disableButtons, setDisableButtons] = useState(false)

  const resolveReport = async () => {
    setDisableButtons(true)

    await fetchAdmin({
      path: `message-reports/resolveAll/${quickbloxDialogId}/${quickbloxMessageId}`,
      method: 'PUT',
    }).then(checkAuth)

    handleClose(true)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateReportTag}>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>
          Resolve all reports associated with this reported message?
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            This operation will mark all reports associated with the reported message as resolved.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button disabled={disableButtons} onClick={resolveReport} color="error">Resolve All</Button>
          <Button disabled={disableButtons} onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default ResolveAllMessageReportsModal
