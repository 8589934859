import React, { useCallback, useEffect, useState } from 'react'

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {
  Box, Button, Card, CardActions, CardContent, Grid, Snackbar, Stack, TextField, Typography,
} from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import { fetchAdmin } from '../../services/fetch'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />)

interface Props {
  goBack: (refreshGrid: boolean) => void
}

function CreateMaintenanceTask(props: Props) {
  const { goBack } = props

  // Task values
  const [description, setDescription] = useState('')
  const [startTime, setStartTime] = useState<Date | null>(new Date())
  const [endTime, setEndTime] = useState<Date | null>(new Date())

  // Notifications
  const [showSnackbar, setShowSnackbar] = useState(false)

  // Button states
  const [disableCreateButton, setDisableCreateButton] = useState(false)

  const checkData = useCallback(() => {
    if (description === '' || !startTime || !endTime) {
      setDisableCreateButton(true)
    } else {
      setDisableCreateButton(false)
    }
  }, [description, startTime, endTime])

  useEffect(() => {
    checkData()
  }, [checkData])

  const handleCreate: React.MouseEventHandler<HTMLButtonElement> = async () => {
    if ((startTime == null) || (endTime == null) || !startTime.toISOString() || !endTime.toISOString() || description === '') {
      setShowSnackbar(true)
      return
    }

    setDisableCreateButton(true)

    await fetchAdmin({
      path: 'maintenance/',
      method: 'POST',
      body: {
        description,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      },
    })

    goBack(true)
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return
    setShowSnackbar(false)
  }

  return (
    <>
      <Box sx={{
        width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
      }}
      >
        <Button
          startIcon={<NavigateBeforeIcon />}
          onClick={() => goBack(false)}
          sx={{ alignSelf: 'flex-start' }}
        >
          Maintenance
        </Button>

        <Stack direction="row">
          <Typography variant="h2" gutterBottom>
            Create Maintenance Task
          </Typography>
        </Stack>

        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Typography variant="h4" gutterBottom>
                  Task Details
                </Typography>

                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  error={description === ''}
                  helperText={description === '' ? 'Required' : ''}
                />

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={(newStartTime) => setStartTime(newStartTime)}
                    slotProps={{
                      textField: {
                        sx: { maxWidth: 170 },
                      },
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label="End Time"
                    value={endTime}
                    onChange={(newEndTime) => setEndTime(newEndTime)}
                    slotProps={{
                      textField: {
                        sx: { maxWidth: 170 },
                      },
                    }}
                  />
                </LocalizationProvider>

              </CardContent>
              <CardActions>
                <Button disabled={disableCreateButton} onClick={handleCreate} color="info">Create</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          Invalid time
        </Alert>
      </Snackbar>
    </>
  )
}

export default CreateMaintenanceTask
