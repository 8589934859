import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box, Checkbox, FormControlLabel, IconButton, Tooltip,
  Typography,
} from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_ColumnFiltersState as MrtColumnFiltersState,
  type MRT_PaginationState as MrtPaginationState,
  type MRT_Row as MrtRow,
  type MRT_SortingState as MrtSortingState,
} from 'material-react-table';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface MrtDataGridProps<T extends Record<string, any>> {
  columns: MrtColumnDef<T>[];
  data: T[];
  totalRows: number;
  isLoading: boolean;
  isError: boolean;
  onRefetch: () => void;
  onClearFilters?: () => void;
  onColumnFiltersChange: React.Dispatch<React.SetStateAction<MrtColumnFiltersState>>;
  setPagination: React.Dispatch<React.SetStateAction<MrtPaginationState>>;
  pagination: MrtPaginationState;
  setSorting: React.Dispatch<React.SetStateAction<MrtSortingState>>;
  sorting: MrtSortingState;
  initialState?: {
    showColumnFilters?: boolean;
    density?: 'compact' | 'comfortable' | 'spacious';
  };
  showDeletedCheckbox?: boolean;
  showResolvedCheckbox?: boolean;
  showDeleted?: boolean;
  showResolved?: boolean;
  setShowDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResolved?: React.Dispatch<React.SetStateAction<boolean>>;
  customRowStyle?: (row: MrtRow<T>) => Record<string, any>;
  onRowDoubleClick?: (row: MrtRow<T>) => void
  topToolbarContent?: ReactJSXElement
}

function MrtDataGrid<T extends Record<string, any>>({
  columns,
  data,
  totalRows,
  isLoading,
  isError,
  onRefetch,
  onClearFilters,
  onColumnFiltersChange,
  setPagination,
  pagination,
  setSorting,
  sorting,
  initialState,
  showDeletedCheckbox = false,
  showResolvedCheckbox = false,
  showDeleted,
  showResolved,
  setShowDeleted,
  setShowResolved,
  customRowStyle,
  onRowDoubleClick,
  topToolbarContent,
}: MrtDataGridProps<T>) {
  const navigate = useNavigate();

  const handleRowDoubleClick = (row: MrtRow<T>) => {
    if (onRowDoubleClick) {
      onRowDoubleClick(row)
    } else {
      navigate(`/users/${row.id}`);
    }
  }

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: totalRows,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      density: 'compact',
      ...initialState,
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableFacetedValues: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableMultiSort: false,
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => handleRowDoubleClick(row),
      sx: {
        cursor: 'pointer',
        ...(customRowStyle ? customRowStyle(row) : {}),
      },
    }),
    state: {
      isLoading,
      sorting,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [25, 50, 100],
      shape: 'rounded',
      variant: 'outlined',
    },
    columnFilterDisplayMode: 'subheader',
    onColumnFiltersChange,
    onPaginationChange: setPagination,
    onSortingChange: (updaterOrValue) => {
      const newSorting = typeof updaterOrValue === 'function'
        ? updaterOrValue(sorting)
        : updaterOrValue;
      setSorting(newSorting.slice(0, 1));
    },
    muiTablePaperProps: {
      sx: {
        display: 'flex', flexDirection: 'column',
      },
    },
    renderTopToolbarCustomActions: () => (
      <Box>
        {topToolbarContent}
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={onRefetch}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Clear Filters">
          <IconButton onClick={onClearFilters}>
            <FilterAltOffIcon />
          </IconButton>
        </Tooltip>
        {showDeletedCheckbox && (
          <FormControlLabel
            control={(
              <Checkbox
                color="error"
                checked={showDeleted}
                onChange={(e) => setShowDeleted?.(e.target.checked)}
              />
            )}
            label="Include Deleted"
          />
        )}
        {showResolvedCheckbox && (
          <FormControlLabel
            control={(
              <Checkbox
                checked={showResolved}
                onChange={(e) => setShowResolved?.(e.target.checked)}
              />
            )}
            label="Include Resolved"
          />
        )}
      </Box>
    ),
    renderBottomToolbarCustomActions: () => (
      <Typography variant="caption" color="red" sx={{ p: 2 }}>
        *Double-click a row to navigate to the details.
      </Typography>
    ),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        maxWidth: 'fit-content',
      }}
    >
      <MaterialReactTable table={table} />
    </Box>
  );
}

export default MrtDataGrid;
