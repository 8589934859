import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { useState } from 'react'

interface Props {
  thumbnailUrl?: string
  videoUrl?: string
}

function VideoCard(props: Props) {
  const { thumbnailUrl, videoUrl } = props
  const [showThumbnail, setShowThumbnail] = useState(true)
  const [showVideo, setShowVideo] = useState(false)
  const [showThumbnailError, setShowThumbnailError] = useState(false)
  const [showVideoError, setShowVideoError] = useState(false)

  function handleShowVideo() {
    setShowThumbnail(false)
    setShowThumbnailError(false)
    setShowVideo(true)
  }

  function handleThumbnailError() {
    setShowThumbnail(false)
    setShowThumbnailError(true)
  }

  function handleVideoError() {
    setShowVideo(false)
    setShowVideoError(true)
  }

  return (
    <Card>
      {
        showThumbnail
          && (
            <CardMedia
              component="img"
              image={thumbnailUrl || makePublicUrl({ path: '/thumbnail-not-found.png' })}
              alt="Thumbnail"
              sx={{ cursor: 'pointer' }}
              onError={handleThumbnailError}
              onClick={handleShowVideo}
            />
          )
      }

      {
        showVideo
          && (
            <CardMedia
              component="video"
              autoPlay
              controls
              src={videoUrl || makePublicUrl({ path: '/video-not-found.png' })}
              onError={handleVideoError}
            />
          )
      }

      {
        showThumbnailError
          && (
            <CardMedia
              component="img"
              image={makePublicUrl({ path: '/thumbnail-not-found.png' })}
              alt="Thumbnail not found"
              onClick={handleShowVideo}
            />
          )
      }

      {
        showVideoError
          && (
            <CardMedia
              component="img"
              image={makePublicUrl({ path: '/video-not-found.png' })}
              alt="Video not found"
            />
          )
      }
    </Card>
  )
}

function makePublicUrl({ path }: { path: string }): string {
  return process.env.REACT_APP_PUBLIC_URL
    ? (new URL(path, process.env.REACT_APP_PUBLIC_URL)).href
    : path
}

export default VideoCard
