import React, { useState, useEffect } from 'react'
import eventBus from '../../services/eventBus'
import MomentReportDetails from './MomentReportDetails'
import MomentReportGrid from './MomentReportGrid'
import { EVENT } from '../../constants'

export interface MomentReportInfo {
  category: string
  createdAt: string
  deletedAt: string | null
  details: string
  id: number
  momentIdentifier: string
  quickbloxUserId: number
  resolvedAt: string | null
  updatedAt: string
  moment: {
    quickbloxUserId: number
  }
}

function MomentReports() {
  const [currReportInfo, setCurrReportInfo] = useState({} as MomentReportInfo)
  const [showGrid, setShowGrid] = useState(true)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    function showReportHelper(e: CustomEvent) {
      setShowGrid(false)
      setCurrReportInfo(e.detail)
    }
    eventBus.on(EVENT.SHOW_MOMENT_REPORT_DETAILS, showReportHelper)

    function goBackHelper() {
      setRefresh((r) => !r)
      setShowGrid(true)
    }
    eventBus.on('goBackToMomentReports', goBackHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_MOMENT_REPORT_DETAILS, showReportHelper)
      eventBus.remove('goBackToMomentReports', goBackHelper)
    }
  }, [])

  return (
    <>
      <MomentReportGrid
        refresh={refresh}
        show={showGrid}
      />
      {showGrid ? null : <MomentReportDetails reportInfo={currReportInfo} />}
    </>
  )
}

export default MomentReports
