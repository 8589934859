import { GridColumns, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid'
import { CardMedia, Typography } from '@mui/material'
import { COLUMN_WIDTH, DIALOG_TYPES, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, {
  DateCell,
  buildDetailsButton,
  filterDateTimeOps,
  filterStringOps,
  fliterSingleSelectOps,
} from '../common/DataGrid'
import AttachmentDetailsCard from './AttachmentDetailsCard'

interface Props {
  show: boolean
  refresh: boolean
}

function ChatGrid(props: Props) {
  const { show, refresh } = props

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="chat-messages-reports"
      columns={columns}
      onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_CHAT_DETAILS, row.row)}
      searchColumns={[
        { label: 'Message', field: 'message' },
        { label: 'Sender ID', field: 'senderId' },
        { label: 'Sender Name', field: 'senderName' },
        { label: 'Dialog ID', field: 'chatDialogId' },
      ]}
      rowHeight={150}
      showTimeZoneSelect
    />
  );
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_CHAT_DETAILS })

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 70,
    sortable: true,
    filterOperators: filterStringOps,
  },
  {
    field: 'senderId',
    headerName: 'Sender ID',
    width: 100,
    filterOperators: filterStringOps,
  },
  {
    field: 'senderName',
    headerName: 'Sender Name',
    width: 120,
    filterOperators: filterStringOps,
  },
  {
    field: 'chatDialogId',
    headerName: 'Dialog ID',
    width: 220,
    filterOperators: filterStringOps,
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 200,
    sortable: false,
    filterOperators: filterStringOps,
    renderCell: (params) => (
      <Typography variant="body2" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    width: 170,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const { attachments, senderId, chatDialogId } = params.row;
      const attachment = attachments?.[0]; // Assuming the first attachment is to be displayed

      if (!attachment) {
        return <Typography>No Attachment</Typography>;
      }

      return (
        <AttachmentDetailsCard
          userId={senderId}
          attachment={attachment}
          dialogId={chatDialogId}
        />
      );
    },
  },
  {
    field: 'attachments',
    headerName: 'Type',
    width: 100,
    sortable: false,
    renderCell: (params) => {
      const attachment = params.row.attachments?.[0];
      if (attachment) {
        return <Typography>{attachment.type}</Typography>;
      }
      return <Typography>none</Typography>;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: ' ',
    headerName: '',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

export default ChatGrid
