import CircleIcon from '@mui/icons-material/Circle'
import HelpIcon from '@mui/icons-material/Help'
import {
  Autocomplete, Box,
  Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Popover, Select, SelectChangeEvent, Stack, TextField, Typography,
} from '@mui/material'
import { blue, orange, red } from '@mui/material/colors'
import { GridToolbarQuickFilter } from '@mui/x-data-grid'
import React, { useContext, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import { TIMEZONES, EVENTMOMENT } from '../../constants'
import { TimezoneContext } from '../app/Timezone'

type FormData = {
  from: moment.Moment | null,
  to: moment.Moment | null,
}

interface Props {
  showDeletedCheckbox: boolean
  showDeleted: boolean
  setShowDeleted: (value: boolean) => void
  showResolvedCheckbox: boolean
  showResolved: boolean
  setShowResolved: (value: boolean) => void
  setSearchColumn: (value: string) => void
  autoCompleteOptions: Array<{
    label: string
    field: string
  }>
  customRowDescriptions: Array<{
    rowClassName: string
    description: string
  }>
  showTimeZoneSelect?: boolean
  showContainDateRange?: boolean
  formData: FormData
  onDateChange: (newDateRange: object) => void
}

function checkYear(data: { name: string, dateFrom: string, dateTo: string }): moment.Moment {
  const obtain = EVENTMOMENT.find((event) => event.name === data.name) || EVENTMOMENT[0]
  const end = moment(obtain.dateTo).year()
  const start = moment(obtain.dateFrom).year()
  const yearAdjustment = end - start
  return moment(obtain.dateTo).year(moment().year() + yearAdjustment)
}

export function QuickSearchToolbar(props: Props) {
  const {
    showDeletedCheckbox,
    showDeleted,
    setShowDeleted,
    showResolvedCheckbox,
    showResolved,
    setShowResolved,
    setSearchColumn,
    autoCompleteOptions,
    customRowDescriptions,
    showTimeZoneSelect,
    showContainDateRange,
    formData,
    onDateChange,
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedEvent, setSelectedEvent] = useState(EVENTMOMENT[0].name)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const updateFormData = (newCond: object) => {
    onDateChange(newCond)
  }

  const handleEventChange = (event: SelectChangeEvent<string>) => {
    const eventName = event.target.value as string;
    const selected = EVENTMOMENT.find((e) => e.name === eventName)
    if (selected) {
      setSelectedEvent(eventName)
      updateFormData({
        from: moment(selected.dateFrom).year(moment().year()),
        to: checkYear(selected),
        timezone: selected.timezone,
      })
    }
  }
  // const handleButtonClick = () => {
  //   console.log('Button inside dropdown clicked!');
  // }

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={4}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        { showResolvedCheckbox
        && (
        <FormGroup sx={{ ml: 1 }}>
          <FormControlLabel
            control={(
              <Checkbox
                color="success"
                checked={showResolved}
                onChange={() => setShowResolved(!showResolved)}
              />
            )}
            label="Resolved"
          />
        </FormGroup>
        )}
        { showDeletedCheckbox
        && (
        <FormGroup sx={{ ml: 1 }}>
          <FormControlLabel
            control={(
              <Checkbox
                color="error"
                checked={showDeleted}
                onChange={() => setShowDeleted(!showDeleted)}
              />
            )}
            label="Include Deleted"
          />
        </FormGroup>
        )}
      </Grid>
      <Grid
        item
        xs={8}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ p: 1, pb: 0 }}
      >
        <Box display="flex" />
        { showContainDateRange
        && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormGroup sx={{ mx: 1, mb: 1 }}>
            <Box display="flex" flexDirection="row" sx={{ mt: 1 }}>
              <FormControl sx={{ minWidth: 170, mr: 1 }}>
                <InputLabel id="event-select-label">Shortcut Event</InputLabel>
                <Select
                  labelId="event-select-label"
                  id="event-select"
                  value={selectedEvent}
                  onChange={handleEventChange}
                  label="Shortcut Event"
                >
                  {EVENTMOMENT.map((event) => (
                    <MenuItem key={event.name} value={event.name}>
                      {event.name}
                    </MenuItem>
                  ))}
                  {/* <MenuItem>
                    <Button onClick={handleButtonClick} variant="contained" size="small">Add New Event</Button>
                  </MenuItem> */}
                </Select>
              </FormControl>
              <DatePicker
                label="from"
                value={formData.from}
                onChange={(value) => updateFormData({ from: value })}
                format="YYYY/MM/DD"
                slotProps={{
                  textField: {
                    sx: { maxWidth: 170, mr: 1 },
                  },
                }}
              />
              <DatePicker
                label="to"
                value={formData.to}
                onChange={(value) => updateFormData({ to: value })}
                format="YYYY/MM/DD"
                slotProps={{
                  textField: {
                    sx: { maxWidth: 170, mr: 1 },
                  },
                }}
              />
            </Box>
          </FormGroup>
        </LocalizationProvider>
        )}
        {
          showTimeZoneSelect
          && <SelectTimeZone />
        }
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) => searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')}
        />
        <Autocomplete
          disablePortal
          options={autoCompleteOptions}
          defaultValue={autoCompleteOptions[0]}
          disableClearable
          sx={{ width: 140, height: 68, mx: 1 }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderInput={(params) => <TextField {...params} label="in the Column" variant="standard" />}
          onChange={(event, newValue) => {
            if (newValue) {
              setSearchColumn(newValue.field)
            }
          }}
        />
        {customRowDescriptions && (
          <div>
            <IconButton onClick={handleClick}>
              <HelpIcon />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Stack spacing={1} sx={{ m: 2 }}>
                { customRowDescriptions.find((rowColorDescription) => rowColorDescription.rowClassName === 'row-color-info') && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CircleIcon sx={{ fontSize: 10, color: blue[200] }} />
                  <Typography color="text.secondary">
                    {customRowDescriptions.find((rowColorDescription) => rowColorDescription.rowClassName === 'row-color-info')?.description}
                  </Typography>
                </Stack>
                )}
                { customRowDescriptions.find((rowColorDescription) => rowColorDescription.rowClassName === 'row-color-disabled') && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CircleIcon sx={{ fontSize: 10, color: orange[200] }} />
                  <Typography color="text.secondary">
                    {customRowDescriptions.find((rowColorDescription) => rowColorDescription.rowClassName === 'row-color-disabled')?.description}
                  </Typography>
                </Stack>
                )}
                { customRowDescriptions.find((rowColorDescription) => rowColorDescription.rowClassName === 'row-color-error') && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CircleIcon sx={{ fontSize: 10, color: red[200] }} />
                  <Typography color="text.secondary">
                    {customRowDescriptions.find((rowColorDescription) => rowColorDescription.rowClassName === 'row-color-error')?.description}
                  </Typography>
                </Stack>
                )}
              </Stack>
            </Popover>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

function SelectTimeZone() {
  const { timezone, changeTimezone } = useContext(TimezoneContext)
  const onChange = (event: SelectChangeEvent<string>) => {
    changeTimezone(event.target.value)
  }

  return (
    <FormControl sx={{ minWidth: 100, mr: 1 }}>
      <InputLabel id="quickSearchToolbar-timezone-select-label">timezone</InputLabel>
      <Select
        id="quickSearchToolbar-timezone-select"
        labelId="quickSearchToolbar-timezone-select-label"
        label="timezone"
        value={timezone}
        onChange={onChange}
      >
        {TIMEZONES.map((tz) => (<MenuItem key={`menuitem-${tz.short}`} value={tz.name}>{tz.short}</MenuItem>))}
      </Select>
    </FormControl>
  )
}
