import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetDeleteDialogTag } from '../common/WidgetTagsAuth'

interface Props {
  userName: string
  qbUserId: number
  quickbloxDialogId: string
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function DeleteDialogParticipantModal(props: Props) {
  const {
    userName, qbUserId, quickbloxDialogId, open, handleClose,
  } = props
  const [disableButtons, setDisableButtons] = useState(false)

  const removeParticipant = async () => {
    setDisableButtons(true)

    await fetchAdmin({
      path: `dialogs/${quickbloxDialogId}/participants/${qbUserId}`,
      method: 'DELETE',
    })
    handleClose(true)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteDialogTag}>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>
          {`Remove ${userName} as participant?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once removed, you can&apos;t undo this operation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={disableButtons} onClick={removeParticipant} color="error">Remove</Button>
          <Button disabled={disableButtons} onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default DeleteDialogParticipantModal
