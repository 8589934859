import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH } from '../../constants'
import DataGrid from '../common/DataGrid'

interface Props {
  weeklyLikeMomentId: string | number
}

function WeeklyLikeMomentLikesGrid(props: Props) {
  const { weeklyLikeMomentId } = props
  const show = true
  const refresh = false

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path={`campaign/weekly-like-moments/${weeklyLikeMomentId}}/likes`}
      columns={columns}
      disableToolbar
    />
  )
}

const columns: GridColumns = [
  {
    field: 'quickbloxUserId',
    headerName: 'User ID',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    sortable: false,
    filterable: false,
  },
  {
    field: 'likeNum',
    headerName: 'Like',
    width: 75,
    sortable: false,
    filterable: false,
  },
]

export default WeeklyLikeMomentLikesGrid
