import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetUpdateReportTag } from '../common/WidgetTagsAuth'

interface Props {
  reportId: number
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function ResolveUserReportModal(props: Props) {
  const { reportId, open, handleClose } = props
  const [disableButtons, setDisableButtons] = useState(false)

  const resolveReport = async () => {
    setDisableButtons(true)
    await fetchAdmin({
      path: `user-reports/resolve/${reportId}`,
      method: 'PUT',
    })
    handleClose(true)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateReportTag}>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>
          {`Resolve report #${reportId}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This operation will mark this report as resolved, meaning that no further action from an admin is necessary.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={disableButtons} onClick={resolveReport} color="error">Resolve</Button>
          <Button disabled={disableButtons} onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default ResolveUserReportModal
