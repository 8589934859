import {
  Button,
  Stack,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useState, useEffect } from 'react'
import AdminUserRoleDetails from './AdminUserRoleDetails'
import eventBus from '../../services/eventBus'
import AdminUserRoleGrid from './AdminUserRoleGrid'
import CreateAdminUserRoleModal from './CreateAdminUserRoleModal'
import { EVENT } from '../../constants'

function AdminUsers() {
  const [showCreateAdminUserRoleModal, setShowCreateAdminUserRoleModal] = useState(false)
  const [userRole, setUserRole] = useState('')
  const [refresh, setRefresh] = useState(false) // This state is toggled to rerender the user grid.

  const handleCloseCreateAdminUserRoleModal = (actionTriggered: boolean) => {
    setShowCreateAdminUserRoleModal(false)
    setRefresh((r) => !r)
  }

  const handleOpenCreateAdminUserRoleModal = () => {
    setShowCreateAdminUserRoleModal(true)
  }

  const handleExitUserDetails = () => {
    setUserRole('')
    setRefresh((r) => !r)
  }

  useEffect(() => {
    function showAdminUserHelper(e: CustomEvent) {
      setUserRole(e.detail.role)
    }
    eventBus.on(EVENT.SHOW_ADMIN_USER_DETAILS, showAdminUserHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_ADMIN_USER_DETAILS, showAdminUserHelper)
    }
  }, [])

  return (
    <>
      <Stack direction="row" spacing={2} m={1}>
        {
          userRole === '' && (
            <Button
              onClick={handleOpenCreateAdminUserRoleModal}
              variant="outlined"
              color="info"
              startIcon={<AddIcon />}
            >
              Create New Custom Role
            </Button>
          )
        }
      </Stack>
      <AdminUserRoleGrid show={!userRole} refresh={refresh} />
      { userRole !== ''
        && <AdminUserRoleDetails userRole={userRole} backText="Roles" exitUserDetails={handleExitUserDetails} />}
      <CreateAdminUserRoleModal
        open={showCreateAdminUserRoleModal}
        handleClose={handleCloseCreateAdminUserRoleModal}
      />
    </>
  )
}

export default AdminUsers
