import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { fetchAdmin } from '../../services/fetch'
import DeleteAdminUserRoleModal from './DeleteAdminUserRoleModal';
import WidgetTagsAuth, { expectedWidgetDeleteAdminTag } from '../common/WidgetTagsAuth'

type WidgetPermission = {
  tag: string
  userRole: string
  isEnabled: boolean
}

interface Props {
  userRole: string
  backText: string // text for the back button
  exitUserDetails: (deletedUser: boolean) => void // handler for the back button
}

function AdminUserRoleDetails(props: Props) {
  const {
    userRole, backText, exitUserDetails,
  } = props
  const [details, setDetails] = useState({
    userRole: '',
    rows: [] as WidgetPermission[],
  })
  const [showDeleteAdminUserRoleModal, setShowDeleteAdminUserRoleModal] = useState(false);

  // eslint-disable-next-line no-undef
  const handleCheckbox = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await fetchAdmin({
      path: 'widgetPermission',
      method: 'PUT',
      body: {
        tag: event.target.value,
        userRole,
        isEnabled: event.target.checked,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setDetails({
          userRole,
          rows: json.rows,
        })
      })
      // eslint-disable-next-line no-alert
      .catch(() => alert('update: error'))
  }

  useEffect(() => {
    setDetails({
      userRole,
      rows: [],
    })

    fetchAdmin({
      path: 'widgetPermissions',
      method: 'GET',
      queries: {
        userRole,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        const enabledRows = json.rows.filter((row: WidgetPermission) => {
          if (userRole === 'viewer' || userRole === 'editor') {
            return row.isEnabled;
          }
          return true;
        });
        setDetails({
          userRole,
          rows: enabledRows,
        })
      })
      .catch(() => {})

    return () => {
    }
  }, [userRole])

  const handleCloseDeleteAdminUserRoleModal = (actionTriggered: boolean) => {
    setShowDeleteAdminUserRoleModal(false);
    if (actionTriggered) {
      exitUserDetails(true);
    }
  };

  const handleOpenDeleteAdminUserRoleModal = () => {
    setShowDeleteAdminUserRoleModal(true);
  };

  return (
    <>
      <Box sx={{
        width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
      }}
      >
        {backText
          && (
          <Button
            startIcon={<NavigateBeforeIcon />}
            onClick={() => exitUserDetails(false)}
            sx={{ alignSelf: 'flex-start' }}
          >
            {backText}
          </Button>
          )}
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography variant="h4" m={0}>
              {details.userRole}
            </Typography>
            <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteAdminTag}>
              {details.userRole && details.userRole !== 'admin' && details.userRole !== 'viewer' && details.userRole !== 'editor' && (
              <Button
                onClick={handleOpenDeleteAdminUserRoleModal}
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
              >
                Delete Role
              </Button>
              )}
            </WidgetTagsAuth>
            <DeleteAdminUserRoleModal
              open={showDeleteAdminUserRoleModal}
              userRole={userRole}
              handleClose={handleCloseDeleteAdminUserRoleModal}
            />
          </Stack>
        </Stack>
        <TableContainer>
          <Table>
            <TableBody>
              {details.rows.map((row) => (
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {row.tag}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      defaultChecked={row.isEnabled}
                      value={row.tag}
                      onChange={handleCheckbox}
                      disabled={userRole === 'admin' || userRole === 'viewer' || userRole === 'editor'}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default AdminUserRoleDetails
