import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { TextField } from '@mui/material'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  name: string
  quickbloxDialogId: string
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function EditDialogNameModal(props: Props) {
  const {
    name, quickbloxDialogId, open, handleClose,
  } = props

  const [newName, setNewName] = React.useState('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value)
  }

  const deleteDialogName = async () => {
    await fetchAdmin({
      path: `dialogs/group/${quickbloxDialogId}`,
      method: 'PUT',
      body: {
        name: newName,
      },
    })
    handleClose(true)
  }

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>
        Update the dialog name.
      </DialogTitle>
      <DialogContent>
        <DialogContentText />
        <TextField
          autoFocus
          margin="dense"
          label="Dialog name"
          required
          defaultValue={name}
          multiline
          fullWidth
          variant="standard"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button disabled={newName === ''} onClick={deleteDialogName} color="info">Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialogNameModal
