import CryptoJs, {
  AES, algo, enc, mode, pad, PBKDF2,
} from 'crypto-js'

const COMMON_PASSWORD = "f$JkL?TqNmEpb$p*c.ZgCm~ZC8dr{98Q?k'm<_`d"

const Base64Formatter = {
  stringify(cipherParams: any) {
    return enc.Base64.stringify(cipherParams.ciphertext)
  },

  parse(input: any) {
    return CryptoJs.lib.CipherParams.create({ ciphertext: enc.Base64.parse(input) })
  },
}

const optionPbkdf2 = {
  keySize: 8,
  iterations: 1000,
  hasher: algo.SHA1,
}

const optionAes = {
  mode: mode.CBC,
  padding: pad.Pkcs7,
  format: Base64Formatter,
  iv: enc.Hex.parse(''),
}

const keyCommon = PBKDF2(COMMON_PASSWORD, '', optionPbkdf2)

export function decryption({ dialogId, data }: { dialogId: string, data: string | ArrayBuffer }) {
  const keyDialog = AES.encrypt(dialogId, keyCommon, optionAes)
  const key = PBKDF2(keyDialog.toString(), '', optionPbkdf2)

  if (typeof data === 'string') {
    const decrypted = AES.decrypt(data, key, optionAes)

    return decrypted.toString(enc.Utf8)
  }
  let bin = ''

  for (let i = 0; i < data.byteLength; i += 1024) {
    const u8 = new Uint8Array(data.slice(i, i + 1024))

    for (let j = 0; j < u8.byteLength; j++) {
      bin += String.fromCharCode(u8[j])
    }
  }

  const decrypted = AES.decrypt(btoa(bin), key, optionAes)

  return decrypted.toString(enc.Base64)
}
