import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useState } from 'react'
import { fetchAdmin } from '../../services/fetch'

function Summary() {
  const [from, setFrom] = useState<moment.Moment | null>(
    moment().startOf('month'),
  )
  const [to, setTo] = useState<moment.Moment | null>(
    moment().endOf('month'),
  )
  const [timezone, setTimezone] = useState('Asia/Tokyo')
  const [status, setStatus] = useState({
    disableSubmit: false,
    isLoading: false,
    rows: [] as Array<Record<string, any>>,
    baseRows: [] as Array<Record<string, any>>,
    afterRow: null as Record<string, any> | null,
    submitedTimezone: '',
    submitedDaysAfter: '',
    submitedUseCache: '',
  })
  const updateStatus = (newStatus: object) => {
    setStatus((old) => ({ ...old, ...newStatus }))
  }
  const onSubmit = async () => {
    updateStatus({
      disableSubmit: true,
      isLoading: true,
      submitedTimezone: timezone,
    })

    try {
      const response = await fetchAdmin({
        path: 'campaign/summaries',
        method: 'GET',
        queries: {
          from: from?.format('YYYYMMDD'),
          to: to?.format('YYYYMMDD'),
          tz: timezone,
        },
      })
      const json = await response.json()
      updateStatus({
        rows: json,
        baseRows: json,
        afterRow: null,
        disableSubmit: false,
        isLoading: false,
      })
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <DatePicker
                  label="from"
                  value={from}
                  onChange={(value) => setFrom(value)}
                  format="YYYY/MM/DD"
                  slotProps={{
                    textField: {
                      sx: { maxWidth: 170 },
                    },
                  }}
                />

                <DatePicker
                  label="to"
                  value={to}
                  onChange={(value) => setTo(value)}
                  format="YYYY/MM/DD"
                  slotProps={{
                    textField: {
                      sx: { maxWidth: 170 },
                    },
                  }}
                />

                <FormControl sx={{ minWidth: 100 }}>
                  <InputLabel id="kpi-timezone-select-label">
                    timezone
                  </InputLabel>
                  <Select
                    labelId="kpi-timezone-select-label"
                    id="kpi-timezone-select"
                    value={timezone}
                    label="timezone"
                    onChange={(event) => setTimezone(event.target.value)}
                  >
                    <MenuItem value="America/Los_Angeles">PST</MenuItem>
                    <MenuItem value="Asia/Tokyo">JST</MenuItem>
                    <MenuItem value="UTC">UTC</MenuItem>
                  </Select>
                </FormControl>
              </Stack>

            </Stack>
          </CardContent>

          <CardActions>
            <Button
              variant="outlined"
              onClick={onSubmit}
              disabled={status.disableSubmit}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </LocalizationProvider>

      <DataGrid
        loading={status.isLoading}
        columns={columns}
        rows={status.rows}
        disableColumnFilter
        disableColumnMenu
        hideFooter
        components={{
          LoadingOverlay: LinearProgress,
        }}
      />
    </>
  )
}

const columns: GridColumns = [
  {
    field: 'campaignName',
    headerName: 'Campaign Name',
    width: 250,
    valueGetter: (params) => params.value.toString(),
  },
  {
    field: 'okCount',
    headerName: 'OK',
    width: 130,
    valueGetter: (params) => params.value.toString(),
  },
  {
    field: 'ngCount',
    headerName: 'NG',
    width: 130,
    valueGetter: (params) => params.value.toString(),
  },
  {
    field: 'totalAmount',
    headerName: 'Total Amount',
    width: 130,
    valueGetter: (params) => `$${params.value.toString()}`,
  },
]

export default Summary
