import { createContext } from 'react'
import { createTheme } from '@mui/material/styles'

export const adminTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
})

export const WidgetTagsContext = createContext<string[]>([])
