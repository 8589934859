import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Stack, Typography } from '@mui/material'
import { fetchAdmin } from '../../services/fetch'
import { CountryType, DEFAULT_COUNTRY } from '../../constants'
import WidgetTagsAuth, { expectedWidgetUpdateUserTag } from '../common/WidgetTagsAuth'

interface Props {
  userId: number
  name: string
  oldCountry: CountryType
  newCountry: CountryType
  open: boolean
  handleClose: (actionTriggered: boolean, newRegion?: string) => void
}

function ChangingRegionModal(props: Props) {
  const {
    userId, name, open, handleClose,
  } = props
  const { oldCountry } = props
  const { newCountry } = props

  const changeRegion = async () => {
    try {
      await fetchAdmin({
        path: `users/${userId}`,
        method: 'PUT',
        body: ({ region: newCountry.code }),
      })
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    }
    handleClose(true, newCountry.code)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag}>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>
          Update the region of
          {' '}
          {name}
          ?
        </DialogTitle>
        <DialogContent>
          <Stack direction="row">
            <Typography sx={{ width: 50, color: 'text.secondary' }}>
              from
            </Typography>
            <Box sx={{ '& > img': { mr: 2, flexShrink: 0 }, width: 30 }}>
              { oldCountry.code !== DEFAULT_COUNTRY.code
                ? (
                  <img
                    alt="country flag"
                    loading="lazy"
                    src={`https://flagcdn.com/w20/${oldCountry.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${oldCountry.code.toLowerCase()}.png 2x`}
                  />
                )
                : null}
            </Box>
            {oldCountry.label}
          </Stack>
          <Stack direction="row">
            <Typography sx={{ width: 50, color: 'text.secondary' }}>
              to
            </Typography>
            <Box sx={{ '& > img': { mr: 2, flexShrink: 0 }, width: 30 }}>
              { newCountry.code !== DEFAULT_COUNTRY.code
                ? (
                  <img
                    alt="country flag"
                    loading="lazy"
                    src={`https://flagcdn.com/w20/${newCountry.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${newCountry.code.toLowerCase()}.png 2x`}
                  />
                )
                : null}
            </Box>
            {newCountry.label}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={changeRegion} color="info">Update</Button>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default ChangingRegionModal
