import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import { checkAuth } from '../../services/authentication'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  open: boolean
  userName: string
  handleClose: (actionTriggered: boolean) => void
}

function DeleteAdminUserModel(props: Props) {
  const { open, userName, handleClose } = props

  const [values, setValues] = useState({
    userName,
    incorrectLogin: false,
    miscErr: false,
  })

  const handleChange = (prop: string): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      deleteUser()
    }
  }

  const deleteUser = async () => {
    try {
      await fetchAdmin({
        path: 'adminUser',
        method: 'DELETE',
        body: ({ userName: values.userName }),
      })
        .then(checkAuth)
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    }
    handleClose(true)
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>
        Delete admin user.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-userName">Username</InputLabel>
            <Input
              id="standard-adornment-userName"
              type="text"
              value={values.userName}
              onChange={handleChange('userName')}
              onKeyDown={handleKeyDown}
              disabled
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteUser} color="error">Delete</Button>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAdminUserModel
