import { useState, useEffect } from 'react'
import ChatGrid from './ChatGrid'
import eventBus from '../../services/eventBus'
import { EVENT } from '../../constants'
import ChatDetails from './ChatDetails'

export const EVENT_SHOW_DETAILS = 'showChatMessageDetails'
export const EVENT_GO_BACK = 'goBackToChatMessage'

export interface Attachment {
  id: string;
  name: string;
  size: string;
  type: string;
  'content-type': string;
  width?: string;
  height?: string;
}

export interface ChatInfo {
  id: number,
  idChatMessage: string,
  chatDialogId: string,
  quickbloxUserId: number
  attachments: Attachment[],
  message: string,
  senderId: number,
  senderName: string
  readIds: string,
  deliveredIds: string,
  hookUrl: string,
  userName: string,
  dateSent: number,
  createdAt: string,
  updatedAt: string,
  deletedAt: string,
}

function Chat() {
  const [chatInfo, setChatInfo] = useState({} as ChatInfo)
  const [showGrid, setShowGrid] = useState(true)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    function showDetailsHelper(e: CustomEvent) {
      setShowGrid(false)
      setChatInfo(e.detail)
    }

    function goBackHelper() {
      setRefresh((r) => !r)
      setShowGrid(true)
    }

    eventBus.on(EVENT_GO_BACK, goBackHelper)
    eventBus.on(EVENT_SHOW_DETAILS, showDetailsHelper)

    return () => {
      eventBus.remove(EVENT_GO_BACK, goBackHelper)
      eventBus.remove(EVENT_SHOW_DETAILS, showDetailsHelper)
    }
  }, [])

  return (
    <>
      <ChatGrid
        show={showGrid}
        refresh={refresh}
      />
      {showGrid ? null : (
        <ChatDetails
          details={chatInfo}
        />
      )}
    </>
  )
}

export default Chat
