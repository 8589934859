import { Delete, ReplayCircleFilled } from '@mui/icons-material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {
  Box, Button, Card, CardContent, Divider, Stack, Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchAdmin } from '../../services/fetch'
import { DateText } from '../app/Timezone'
import ConfirmModal from '../common/ConfirmModal'
import WidgetTagsAuth, { expectedWidgetDeleteDebugTag } from '../common/WidgetTagsAuth'

interface Props {
  id?: string
  state?: string
  exitDetails?: () => void
}

interface JobProps {
  id: string
  name: string
  data?: object
  attemptsMade: number
  returnvalue?: object
  failedReason: string
  stacktrace?: string[]
  timestamp: number
  finishedOn?: number
  processedOn?: number
}

const fetchGetJob = makeFetchMethod({
  makePath: (id) => `jobs/${id}`,
  method: 'GET',
})

const fetchRetryJob = makeFetchMethod({
  makePath: (id) => `jobs/${id}/retry`,
  method: 'POST',
})

const fetchDeleteJob = makeFetchMethod({
  makePath: (id) => `jobs/${id}`,
  method: 'DELETE',
})

function JobDetails(props: Props) {
  const { id, state, exitDetails } = props
  const { identifier } = useParams()
  const [status, setStatus] = useState({
    job: {} as JobProps,
    state: '',
  })
  const [showRetryModal, setShowRetryModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const navigate = useNavigate()
  const jobId = id ?? identifier

  const handleExit = useCallback(() => {
    if (exitDetails) {
      exitDetails()
    } else {
      navigate(-1)
    }
  }, [exitDetails, navigate])

  const getJob = useCallback(() => {
    fetchGetJob(jobId, (json) => updateStatus(json))
  }, [jobId])

  const retryJob = useCallback(() => {
    fetchRetryJob(jobId, (json) => (json ? getJob() : null))
  }, [jobId, getJob])

  const deleteJob = useCallback(() => {
    fetchDeleteJob(jobId, (json) => handleExit())
  }, [jobId, handleExit])

  function updateStatus(newStatus: object) {
    setStatus((old) => ({ ...old, ...newStatus }))
  }

  useEffect(() => {
    getJob()
  }, [getJob])

  return status.job.id === undefined
    ? null
    : (
      <>
        <Button
          startIcon={<NavigateBeforeIcon />}
          onClick={handleExit}
        >
          back
        </Button>

        <Stack direction="row">
          <Typography variant="h3" gutterBottom>
            {`Job #${status.job.id}`}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2} mb={2}>
          {
            (status.state || state) === 'failed'
            && (
              <Button
                onClick={() => { setShowRetryModal((show) => !show) }}
                variant="outlined"
                color="info"
                startIcon={<ReplayCircleFilled />}
              >
                Retry
              </Button>
            )
          }
          <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteDebugTag}>
            <Button
              onClick={() => { setShowDeleteModal((show) => !show) }}
              variant="outlined"
              color="error"
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </WidgetTagsAuth>
        </Stack>

        <Card>
          <CardContent>
            <DetailsCell title="Name">
              {status.job.name}
            </DetailsCell>

            <DetailsCell title="State">
              {status.state ? status.state : state}
            </DetailsCell>

            <DetailsCell title="Data">
              <ObjectCell value={status.job.data} />
            </DetailsCell>

            <DetailsCell title="Attempts Made">
              {status.job.attemptsMade}
            </DetailsCell>

            <DetailsCell title="Return Value">
              <ObjectCell value={status.job.returnvalue} />
            </DetailsCell>

            <DetailsCell title="Failed Reason">
              {status.job.failedReason}
            </DetailsCell>

            <DetailsCell title="Stacktrace">
              <ObjectCell value={status.job.stacktrace} />
            </DetailsCell>

            <DetailsCell title="Created At">
              <DateText date={status.job.timestamp} />
            </DetailsCell>

            <DetailsCell title="Processed At">
              <DateText date={status.job.processedOn} />
            </DetailsCell>

            <DetailsCell title="Finished At">
              <DateText date={status.job.finishedOn} />
            </DetailsCell>
          </CardContent>
        </Card>

        <ConfirmModal
          openTrigger={showRetryModal}
          title={`Retry Job #${status.job.id}?`}
          message="Execute this job again."
          onAccept={retryJob}
        />

        <ConfirmModal
          openTrigger={showDeleteModal}
          title={`Delete Job #${status.job.id}?`}
          message="Once removed, you can&apos;t undo this operations."
          onAccept={deleteJob}
        />
      </>
    )
}

function DetailsCell(props: { title: string, children: React.ReactNode }) {
  const { title, children } = props

  return (
    <>
      <Typography variant="body2" component="div" pt={2} sx={{ '&:first-of-type': { paddingTop: 0 } }}>
        <Box sx={{ fontWeight: 'bold' }}>{title}</Box>

        <Box pt={2} pb={2} pl={2}>{children}</Box>
      </Typography>

      <Divider />
    </>
  )
}

function ObjectCell(props: { value: unknown }) {
  const { value } = props
  let element: unknown

  if (value) {
    if (Array.isArray(value)) {
      let idx = 0

      element = value.map((v) => (
        <Box key={++idx}>
          <ObjectCell value={v} />

          <Divider />
        </Box>
      ))
    } else if (typeof value === 'object') {
      element = Object.entries(value).map(([k, v]) => (
        <Box key={k}>
          <Typography variant="caption" component="div">{k}</Typography>
          <Typography variant="body2" ml={2} component="div"><ObjectCell value={v} /></Typography>
        </Box>
      ))
    } else if (typeof value === 'boolean') {
      element = value.toString()
    } else {
      element = value
    }
  }

  return <>{element}</>
}

function makeFetchMethod({
  makePath,
  method,
}: {
  makePath: (id: string | undefined) => string,
  method: 'GET' | 'PUT' | 'POST'| 'DELETE',
}) {
  return (id: string | undefined, cb: (json: any) => void) => {
    fetchAdmin({
      path: makePath(id),
      method,
    })
      .then((res) => res.json())
      .then((json) => cb(json))
      .catch((error) => console.error(error)) // eslint-disable-line no-console
  }
}

export default JobDetails
