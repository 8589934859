import React, { useState, useEffect, useCallback } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import ArrowLeft from '@mui/icons-material/NavigateBefore'
import Delete from '@mui/icons-material/Delete'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import EditIcon from '@mui/icons-material/Edit'
import { Badge, colors } from '@mui/material'
import { styled } from '@mui/material/styles'
import DeleteDialogParticipantModal from './DeleteDialogParticipantModal'
import DeleteDialogModal from './DeleteDialogModal'
import eventBus from '../../services/eventBus'
import { checkIs1on1Chat } from '../../constants'
import { fetchAdmin } from '../../services/fetch'
import DeleteDialogPhotoModal from './DeleteDialogPhotoModal'
import EditDialogNameModal from './EditDialogNameModal'
import EditDialogDescriptionModal from './EditDialogDiscriptionModal'
import WidgetTagsAuth, { expectedWidgetUpdateDialogTag, expectedWidgetDeleteDialogTag } from '../common/WidgetTagsAuth'
import ConfirmModal from '../common/ConfirmModal'
import SwitchingFeatureDialogModal from './SwitchingFeatureDialogModal'
import UserProfileLink from '../user/UserProfileLink'

interface Props {
  quickbloxDialogId: string
}

function DialogDetails(props: Props) {
  const { quickbloxDialogId } = props
  const ONE_ON_ONE_CHAT = '1 on 1 Chat'
  const [details, setDetails] = useState({
    name: '',
    quickbloxDialogId,
    photo: undefined as undefined | string,
    description: '',
    type: '',
    categoryEmoji: '',
    categoryName: '',
    creatorUserId: 0,
    allowSearch: false,
    visible: true,
    // totalGuests: 0 // TODO: decide if we want to show this
    // totalHosts: 0
    // totalMembers: 0
    // totalPeekers: 0
  })
  const [isFeatured, setIsFeatured] = useState(false)
  const [memberDetails, setMemberDetails] = useState({
    participants: [] as Array<{
      quickbloxUserId: number
      userName: string
      fullName: string
      role: string
      isOfficial: boolean
      isStaff: boolean
      isAmbassador: boolean
      customData: {
        avatarUrl: string
      }
    }>,
  })

  const [is1on1Chat, setIs1on1Chat] = useState(false)

  const [showDeleteDialogPhoto, setShowDeleteDialogPhoto] = useState({
    open: false,
  })
  const [showEditDialogName, setShowEditDialogName] = useState({
    open: false,
  })
  const [showEditDialogDescription, setShowEditDialogDescription] = useState({
    open: false,
  })

  const [removeDialogParticipant, setRemoveDialogParticipant] = useState({
    userName: '',
    userId: 0,
    open: false,
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showBanModal, setShowBanModal] = useState(false)
  const [showFeatureDialogModal, setShowFeatureDialogModal] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchAdmin({
        path: `dialogs/${quickbloxDialogId}`,
        method: 'GET',
      })
      const json = await response.json()
      setIs1on1Chat(checkIs1on1Chat(json.data.type))
      setDetails({
        name: json.data.name,
        quickbloxDialogId,
        photo: json.data.photo,
        description: json.data.description,
        type: json.data.type,
        categoryEmoji: json.data.categoryEmoji,
        categoryName: json.data.categoryName,
        creatorUserId: json.data.creatorUserId,
        allowSearch: json.data.allowSearch,
        visible: json.data.visible,
      })
      setIsFeatured(json.data.featured)

      const memberResponse = await fetchAdmin({
        path: `dialogs/${quickbloxDialogId}/members`,
        method: 'GET',
      })
      const memberJson = await memberResponse.json()
      setMemberDetails({
        participants: memberJson.data,
      })
    } catch (err) {
      console.error(err) // eslint-disable-line no-console
    }
  }, [quickbloxDialogId])

  useEffect(() => {
    fetchData()
  }, [fetchData, isFeatured])

  const goBack = () => {
    eventBus.dispatch('goBackToDialogs')
  }

  const handleCloseDeleteDialogParticipantModal = (actionTriggered: boolean) => {
    setRemoveDialogParticipant({ ...removeDialogParticipant, open: false })
    if (actionTriggered) {
      fetchData()
    }
  }

  const handleOpenDeleteDialogParticipantModal = (userName: string, userId: number) => {
    setRemoveDialogParticipant({
      userName,
      userId,
      open: true,
    })
  }

  const handleCloseDeleteDialogPhotoModal = (actionTriggered: boolean) => {
    setShowDeleteDialogPhoto({ open: false })
    if (actionTriggered) {
      fetchData()
    }
  }

  const handleOpenDeleteDialogPhotoModal = () => {
    setShowDeleteDialogPhoto({ open: true })
  }

  const handleCloseEditDialogNameModal = (actionTriggered: boolean) => {
    setShowEditDialogName({ open: false })
    if (actionTriggered) {
      fetchData()
    }
  }

  const handleOpenEditDialogNameModal = () => {
    setShowEditDialogName({ open: true })
  }

  const handleCloseEditDialogDescriptionModal = (actionTriggered: boolean) => {
    setShowEditDialogDescription({ open: false })
    if (actionTriggered) {
      fetchData()
    }
  }

  const handleOpenEditDialogDescriptionModal = () => {
    setShowEditDialogDescription({ open: true })
  }

  const handleCloseDeleteDialogModal = (actionTriggered: boolean) => {
    setShowDeleteDialog(false)
    if (actionTriggered) {
      eventBus.dispatch('goBackToDialogs')
    }
  }

  const handleOpenDeleteDialogModal = () => {
    setShowDeleteDialog(true)
  }

  function handleOpenBanModal() {
    setShowBanModal((show) => !show)
  }

  function handleBanUser() {
    fetchAdmin({
      path: `dialogs/${details.quickbloxDialogId}/ban`,
      method: 'PUT',
    })
      .then(() => { fetchData() })
  }

  function handleOpenFeatureDialogModal() {
    setShowFeatureDialogModal(true)
  }

  function handleCloseFeatureDialogModal(actionTriggered: boolean, newIsFeatured?: boolean) {
    setShowFeatureDialogModal(false)
    if (actionTriggered && newIsFeatured !== undefined) {
      fetchData()
    }
  }

  const SmallIconButton = styled(IconButton)(({ theme }) => ({
    width: 40,
    height: 40,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: colors.grey[200],
    },
    border: `2px solid ${theme.palette.divider}`,
  }))

  return (
    <>
      <Button
        startIcon={<ArrowLeft />}
        onClick={goBack}
      >
        Dialogs
      </Button>
      <Stack direction="row" mx={{ m: 2 }}>
        { is1on1Chat || !details.photo
          ? (
            <Avatar
              src={details.photo}
              sx={{ width: 100, height: 100, m: 2 }}
            >
              <Typography variant="h4">
                {details.name ? details.name.charAt(0) : '?'}
              </Typography>
            </Avatar>
          )
          : (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={(
                <SmallIconButton
                  onClick={() => handleOpenDeleteDialogPhotoModal()}
                  sx={{ color: 'text.secondary' }}
                >
                  <Delete />
                </SmallIconButton>
          )}
              sx={{ m: 2 }}
            >
              <Avatar
                src={details.photo}
                sx={{ width: 100, height: 100 }}
              >
                <Typography variant="h4">
                  {details.name ? details.name.charAt(0) : '?'}
                </Typography>
              </Avatar>
            </Badge>
          )}
        <Stack direction="column" justifyContent="center">
          { is1on1Chat
            ? (
              <Typography variant="h4" m={0} sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                {ONE_ON_ONE_CHAT}
              </Typography>
            )
            : (
              <Stack direction="row">
                <Typography variant="h4" m={0}>
                  {details.name}
                </Typography>
                <IconButton
                  onClick={() => handleOpenEditDialogNameModal()}
                  sx={{ color: 'text.secondary' }}
                >
                  <EditIcon />
                </IconButton>
              </Stack>
            )}
          <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            {details.quickbloxDialogId}
          </Typography>
        </Stack>
      </Stack>
      { is1on1Chat
        ? null // TODO: if you want to delete 1 on 1 chat, you need to make new api call, like helium api, 'DELTE friends/remove'
        : (
          <div>
            <Stack direction="row" spacing={2} m={1}>
              <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteDialogTag}>
                <Button
                  onClick={() => handleOpenDeleteDialogModal()}
                  variant="outlined"
                  color="error"
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
              </WidgetTagsAuth>
              <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateDialogTag}>
                { (details.type !== 'friend')
                && (
                <Button
                  variant={details.visible ? 'outlined' : 'contained'}
                  color="error"
                  startIcon={<Delete />}
                  onClick={handleOpenBanModal}
                >
                  Shadow BAN
                </Button>
                )}
              </WidgetTagsAuth>
            </Stack>
            <Button
              onClick={handleOpenFeatureDialogModal}
              variant={isFeatured ? 'contained' : 'outlined'}
              color="info"
            >
              Featured
            </Button>
          </div>

        )}
      <SwitchingFeatureDialogModal
        dialogId={details.quickbloxDialogId}
        featured={isFeatured}
        open={showFeatureDialogModal}
        handleClose={handleCloseFeatureDialogModal}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card sx={{ p: 1 }}>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Details
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Type
                        </TableCell>
                        <TableCell>
                          {details.type}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Category
                        </TableCell>
                        <TableCell>
                          {details.categoryName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Creator User ID
                        </TableCell>
                        <TableCell>
                          <UserProfileLink userId={details.creatorUserId} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Allow Search
                        </TableCell>
                        <TableCell>
                          {details.allowSearch ? 'true' : 'false'}
                        </TableCell>
                      </TableRow>
                      { is1on1Chat
                        ? null
                        : (
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                              Description
                            </TableCell>
                            <TableCell>
                              {details.description}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => handleOpenEditDialogDescriptionModal()}
                                sx={{ color: 'text.secondary' }}
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  Participants
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>FullName</TableCell>
                        <TableCell>UserName</TableCell>
                        <TableCell>QuickbloxUserId</TableCell>
                        {is1on1Chat
                          ? null
                          : (
                            <>
                              <TableCell>Role</TableCell>
                              <TableCell />
                            </>
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {memberDetails.participants.map((participant) => (
                        <TableRow
                          key={participant.quickbloxUserId}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {participant.fullName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`@${participant.userName}`}
                          </TableCell>
                          <TableCell>
                            <UserProfileLink userId={participant.quickbloxUserId} />
                          </TableCell>
                          {is1on1Chat
                            ? null
                            : (
                              <>
                                <TableCell>{participant.role}</TableCell>
                                <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateDialogTag || expectedWidgetDeleteDialogTag}>
                                  <TableCell>
                                    {details.creatorUserId !== participant.quickbloxUserId && !is1on1Chat
                                      ? (
                                        <IconButton
                                          onClick={() => handleOpenDeleteDialogParticipantModal(participant.userName, participant.quickbloxUserId)}
                                          color="error"
                                        >
                                          <PersonRemoveIcon />
                                        </IconButton>
                                      )
                                      : null}
                                  </TableCell>
                                </WidgetTagsAuth>
                              </>
                            )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <DeleteDialogPhotoModal
          name={details.name}
          quickbloxDialogId={details.quickbloxDialogId}
          photo={details.photo}
          open={showDeleteDialogPhoto.open}
          handleClose={handleCloseDeleteDialogPhotoModal}
        />
        <EditDialogNameModal
          name={details.name}
          quickbloxDialogId={details.quickbloxDialogId}
          open={showEditDialogName.open}
          handleClose={handleCloseEditDialogNameModal}
        />
        <EditDialogDescriptionModal
          description={details.description}
          quickbloxDialogId={details.quickbloxDialogId}
          open={showEditDialogDescription.open}
          handleClose={handleCloseEditDialogDescriptionModal}
        />
        <DeleteDialogParticipantModal
          userName={removeDialogParticipant.userName}
          qbUserId={removeDialogParticipant.userId}
          quickbloxDialogId={details.quickbloxDialogId}
          open={removeDialogParticipant.open}
          handleClose={handleCloseDeleteDialogParticipantModal}
        />
        <DeleteDialogModal
          name={is1on1Chat ? ONE_ON_ONE_CHAT : details.name}
          quickbloxDialogId={quickbloxDialogId}
          open={showDeleteDialog}
          handleClose={handleCloseDeleteDialogModal}
        />
        <ConfirmModal
          openTrigger={showBanModal}
          title={`${details.name}`}
          message={details.visible ? 'Ban this dialog?' : 'Unban this dialog?'}
          onAccept={handleBanUser}
        />
      </Box>
    </>
  )
}

export default DialogDetails
