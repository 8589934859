import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, {
  DateCell,
  buildDetailsButton,
  filterDateTimeOps,
  filterStringOps,
} from '../common/DataGrid'

interface Props {
  show: boolean
  refresh: boolean
}

function MaintenanceGrid(props: Props) {
  const { show, refresh } = props

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="maintenances"
      columns={columns}
      onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_MAINTENANCE_DETAILS, row.row)}
      searchColumns={[
        { label: 'Description', field: 'description' },
      ]}
      initialSortItem={{ field: 'startTime', sort: 'desc' }}
      setCustomRowClassName={(params) => {
        const now = new Date()
        const startTime = new Date(params.row.startTime)
        const endTime = new Date(params.row.endTime)
        if (startTime >= now && startTime <= endTime) return 'row-color-info'
        return ''
      }}
      setCustomRowDescriptions={[
        { rowClassName: 'row-color-info', description: 'Current' },
      ]}
    />
  )
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_MAINTENANCE_DETAILS })

const columns: GridColumns = [
  {
    field: 'description',
    headerName: 'Description',
    width: 280,
    filterOperators: filterStringOps,
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={(new Date(params.value)).toISOString()} />
    ),
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={(new Date(params.value)).toISOString()} />
    ),
  },
  {
    field: ' ',
    headerName: '',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

export default MaintenanceGrid
