import { Box, Stack, TextField } from '@mui/material'
import { GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useState } from 'react'
import { COLUMN_WIDTH, EVENT } from '../../constants'
import DataGrid, { buildDetailsButton, DateCell } from '../common/DataGrid'
import eventBus from '../../services/eventBus'

function WeeklyLikeMomentsGrid({
  show,
  refresh,
}: {
  show: boolean,
  refresh: boolean,
}) {
  const onRowDoubleClick = (params: GridRowParams | GridRenderCellParams) => {
    eventBus.dispatch(EVENT.SHOW_DETAILS, params.row)
  }

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="campaign/weekly-like-moments"
      columns={columns}
      toolbarComponent={SearchToolbar}
      initToolbarQueries={initToolbarQueries}
      onRowDoubleClick={onRowDoubleClick}
    />
  )
}

const DetailsButton = buildDetailsButton({ event: EVENT.SHOW_DETAILS })

const initToolbarQueries = {
  week: moment().subtract(1, 'week').format('YYYYWW'),
}

const columns: GridColumns = [
  {
    field: 'likeNum',
    headerName: 'Like',
    width: 75,
    sortable: false,
    filterable: false,
  },
  {
    field: 'quickbloxUserId',
    headerName: 'User ID',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    sortable: false,
    filterable: false,
  },
  {
    field: 'momentIdentifier',
    headerName: 'Identifier',
    width: 350,
    sortable: false,
    filterable: false,
  },
  {
    field: 'rewardRequested',
    headerName: 'GiftCode Requested',
    width: 150,
    sortable: false,
    filterable: false,
    renderCell: (row) => <>{row.row.rewardRequested ? 'yes' : 'no'}</>,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
    sortable: false,
    filterable: false,
  },
  {
    field: 'detailsButton',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

function SearchToolbar(props: any) {
  const { toolbarQueries, setToolbarQueries } = props
  const [week, setWeek] = useState(toolbarQueries.week)
  const dateWeek = week
    ? moment(week.slice(0, 4), 'YYYY').set('week', week.slice(4, 6))
    : moment()

  const updateDateWeek = (date: moment.Moment | null) => {
    if (date) {
      setWeek(date.format('YYYYWW'))
      setToolbarQueries((old: object) => ({
        ...old,
        ...{ week: date.format('YYYYWW') },
      }))
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box padding={1}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="start"
            value={dateWeek.clone().day(1)}
            onChange={(value) => { updateDateWeek(value) }}
            format="YYYY/MM/DD"
            slotProps={{
              textField: {
                sx: { maxWidth: 170 },
              },
            }}
          />

          <DatePicker
            label="end"
            value={dateWeek.clone().day(7)}
            onChange={(value) => { updateDateWeek(value) }}
            format="YYYY/MM/DD"
            slotProps={{
              textField: {
                sx: { maxWidth: 170 },
              },
            }}
          />
        </Stack>
      </Box>
    </LocalizationProvider>
  )
}

export default WeeklyLikeMomentsGrid
