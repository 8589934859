import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { checkAuth } from '../../services/authentication';
import { fetchAdmin } from '../../services/fetch';

interface Props {
  open: boolean;
  userRole: string;
  handleClose: (actionTriggered: boolean) => void;
}

function DeleteAdminUserRoleModal(props: Props) {
  const { open, userRole, handleClose } = props;

  const [values, setValues] = useState({
    userRole,
    incorrectLogin: false,
    miscErr: false,
  });

  const handleChange = (prop: string): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      deleteUserRole();
    }
  };

  const deleteUserRole = async () => {
    try {
      await fetchAdmin({
        path: 'adminUserRole',
        method: 'DELETE',
        body: ({ userRole: values.userRole }),
      })
        .then(checkAuth);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
    handleClose(true);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>
        Delete user role.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-userRole">User Role</InputLabel>
            <Input
              id="standard-adornment-userRole"
              type="text"
              value={values.userRole}
              onChange={handleChange('userRole')}
              onKeyDown={handleKeyDown}
              disabled
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteUserRole} color="error">Delete</Button>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAdminUserRoleModal;
