import { lightBlue, orange, red } from '@mui/material/colors';

export const styleObj = {
  error: {
    bgcolor: red[50],
    '&:hover, &.Mui-hovered': {
      bgcolor: red[100],
    },
  },
  info: {
    bgcolor: lightBlue[50],
    '&:hover, &.Mui-hovered': {
      bgcolor: lightBlue[100],
    },
  },
  disabled: {
    bgcolor: orange[50],
    '&:hover, &.Mui-hovered': {
      bgcolor: orange[100],
    },
  },
}
