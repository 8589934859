import { useState, useEffect } from 'react'

import ExploreGrid from './ExploreGrid'
import DialogDetails from './DialogDetails'
import eventBus from '../../services/eventBus'
import { EVENT } from '../../constants'

function Dialogs() {
  const [curId, setCurId] = useState('')
  const [showDialogDetails, setShowDialogDetails] = useState(false)
  const [refresh, setRefresh] = useState(false) // This state is toggled to rerender the dialog grid.

  useEffect(() => {
    const showDialogHelper = (e: CustomEvent) => {
      setCurId(e.detail.id)
      setShowDialogDetails(true)
    }
    eventBus.on(EVENT.SHOW_DIALOG_DETAILS, showDialogHelper)

    const goBackHelper = () => {
      setCurId('')
      setShowDialogDetails(false)
      setRefresh((r) => !r)
    }
    eventBus.on('goBackToDialogs', goBackHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_DIALOG_DETAILS, showDialogHelper)
      eventBus.remove('goBackToDialogs', goBackHelper)
    }
  }, [])

  return (
    <>
      <ExploreGrid show={!showDialogDetails} refresh={refresh} />
      { !showDialogDetails
        ? null
        : <DialogDetails quickbloxDialogId={curId} />}
    </>
  )
}

export default Dialogs
