import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  open: boolean
  userName: string
  handleClose: (actionTriggered: boolean) => void
}

function UpdatePasswordAdminUserModal(props: Props) {
  const { open, userName, handleClose } = props

  const [values, setValues] = useState({
    userName,
    password: '',
    showPassword: false,
    miscErr: false,
  })

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
  }

  const handleChange = (prop: string): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      updateUser()
    }
  }

  const updateUser = async () => {
    try {
      await fetchAdmin({
        path: 'adminUser',
        method: 'PUT',
        body: {
          userName: values.userName,
          password: values.password,
        },
      })
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    }
    handleClose(true)
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>
        Update admin user password.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-userName">Username</InputLabel>
            <Input
              id="standard-adornment-userName"
              type="text"
              value={values.userName}
              onChange={handleChange('userName')}
              onKeyDown={handleKeyDown}
              disabled
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              id="standard-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              onKeyDown={handleKeyDown}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
          )}
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={updateUser} color="info">Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdatePasswordAdminUserModal
