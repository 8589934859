import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, {
  DateCell,
  buildDetailsButton,
  filterDateTimeOps,
  filterNumericOps,
  filterStringOps,
} from '../common/DataGrid'

interface Props {
  show: boolean
  refresh: boolean
}

function MessageReportGrid(props: Props) {
  const { show, refresh } = props

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="message-reports"
      columns={columns}
      onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_MESSAGE_REPORT_DETAILS, row.row)}
      showResolvedCheckbox
      searchColumns={[
        { label: 'Category', field: 'category' },
        { label: 'Reported UserId', field: 'reportedQuickbloxUserId' },
      ]}
      showTimeZoneSelect
    />
  )
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_MESSAGE_REPORT_DETAILS })

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: COLUMN_WIDTH.ID,
    filterOperators: filterNumericOps,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: COLUMN_WIDTH.CATEGORY,
    filterOperators: filterStringOps,
  },
  {
    field: 'details',
    headerName: 'Report Message',
    width: COLUMN_WIDTH.DETAILS,
    filterOperators: filterStringOps,
  },
  {
    field: 'reportedQuickbloxUserId',
    headerName: 'Reported User ID',
    type: 'string',
    width: COLUMN_WIDTH.LONG_QUICKBLOX_USER_ID,
    filterOperators: filterNumericOps,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'resolvedAt',
    headerName: 'Resolved At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: ' ',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

export default MessageReportGrid
