import { useNavigate, useParams } from 'react-router-dom'
import MomentDetails from './MomentDetails'

function MomentDetailsRouter() {
  const { identifier } = useParams()
  const navigate = useNavigate()
  const exit = () => {
    navigate(-1)
  }

  return (
    <MomentDetails
      identifier={identifier as string}
      backText="back"
      exitDetails={exit}
    />
  )
}

export default MomentDetailsRouter
