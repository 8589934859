import { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { useState } from 'react'
import JobDetails from './JobDetails'
import JobGrid from './JobGrid'

function Jobs() {
  const [showGrid, setShowGrid] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [jobId, setJobId] = useState('')
  const [jobState, setJobState] = useState('')
  const goDetails = (params: GridRowParams | GridRenderCellParams) => {
    setShowGrid(false)
    setJobId(params.row.id)
    setJobState(params.row.state)
  }
  const exitDetails = () => {
    setJobId('')
    setShowGrid(true)
    setRefresh((r) => !r)
  }

  return (
    <>
      <JobGrid
        show={showGrid}
        refresh={refresh}
        goDetails={goDetails}
      />

      {
        jobId
        && (
          <JobDetails
            id={jobId}
            state={jobState}
            exitDetails={exitDetails}
          />
        )
      }
    </>
  )
}

export default Jobs
