import { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// eslint-disable-next-line
import { useDropzone } from 'react-dropzone';
import { fetchAdmin } from '../../services/fetch';

function UploadImages() {
  const [userId, setUserId] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [uploadStatus, setUploadStatus] = useState('Not Uploaded');
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDelete = (fileToDelete: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('chatRoomId', chatRoomId);

    files.forEach((file) => {
      formData.append('images', file);
    });

    try {
      const totalFiles = files.length;
      let uploadedCount = 0;

      for (const file of files) {
        const individualFormData = new FormData();
        individualFormData.append('userId', userId);
        individualFormData.append('chatRoomId', chatRoomId);
        individualFormData.append('images', file);

        const response = await fetchAdmin({
          path: 'upload-images',
          method: 'POST',
          body: individualFormData,
        });

        if (response.ok) {
          uploadedCount += 1;
          setUploadProgress((uploadedCount / totalFiles) * 100);
        } else {
          setUploadStatus(`Upload Failed: ${response.statusText}`);
          return;
        }
      }

      setUploadStatus('Upload Successful');
      setFiles([]); // Clear the images after successful upload
      setUploadProgress(0); // Reset upload progress
    } catch (error) {
      setUploadStatus('Upload Failed');
      setUploadProgress(0); // Reset upload progress on error
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
    },
  });

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Upload Images to Chat Room
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Chat Room ID"
            value={chatRoomId}
            onChange={(e) => setChatRoomId(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Grid container spacing={2} alignItems="center" margin="normal">
            <Grid item xs={8}>
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed gray',
                  padding: '30px',
                  textAlign: 'center',
                  marginTop: '16px',
                  cursor: 'pointer',
                }}
              >
                <input {...getInputProps()} />
                <Typography>
                  Drag & drop images here, or click to select files
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="info"
                onClick={handleUpload}
                disabled={files.length === 0}
                sx={{ marginLeft: '20px' }}
              >
                SEND IMAGES
              </Button>
            </Grid>
          </Grid>

          <TableContainer sx={{ marginTop: '20px' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Upload Status
                  </TableCell>
                  <TableCell>{uploadStatus}</TableCell>
                </TableRow>
                {uploadProgress > 0 && (
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Upload Progress
                    </TableCell>
                    <TableCell>{`${uploadProgress.toFixed(2)}%`}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total Files</TableCell>
                  <TableCell>
                    {files.length}
                    {' '}
                    files attached
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <List sx={{ marginTop: '20px' }}>
            {files.map((file) => (
              <ListItem
                key={file.name}
                secondaryAction={(
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(file)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              >
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}

export default UploadImages;
