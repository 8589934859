import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetUpdateUserTag } from '../common/WidgetTagsAuth'

interface Props {
  dialogId: string
  featured: boolean
  open: boolean
  handleClose: (actionTriggered: boolean, newIsFeatured?: boolean) => void
}

function SwitchingFeatureDialogModal(props: Props) {
  const {
    dialogId, open, handleClose,
  } = props
  let { featured } = props

  const switchIsFeatured = async () => {
    try {
      featured = !featured
      await fetchAdmin({
        path: `dialogs/featured/${dialogId}`,
        method: 'PUT',
        body: ({ featured }),
      })
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    }
    handleClose(true, featured)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag}>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>
          { featured ? 'Remove the dialog from featured list' : 'Add the dialog to featured list'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText />
        </DialogContent>
        <DialogActions>
          { featured
            ? <Button onClick={switchIsFeatured} color="error">Remove</Button>
            : <Button onClick={switchIsFeatured} color="info">Add</Button>}
          <Button onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default SwitchingFeatureDialogModal
