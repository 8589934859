import {
  Card, CardContent, CardMedia, CircularProgress, Dialog, DialogContent, Typography,
} from '@mui/material'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { decryption } from '../../services/encryption'
import { fetchAdmin } from '../../services/fetch'
import { buildStorageApiUrl } from '../../services/urlBuilder'

interface Props {
  userId: number
  dialogId: string
  messageId: string
  setDisabledDeleteButton?: (state: boolean) => void
}

interface Video {
  url: string
  width: number
  height: number
}

interface Status {
  loading?: boolean
  message?: string
  image?: string
  audio?: string
  video?: Video
  dateSent?: string
  error?: string
}

function QuickbloxMessageDetailsCard(props: Props) {
  const {
    userId,
    dialogId,
    messageId,
    setDisabledDeleteButton,
  } = props
  const [status, setStatus] = useState({ loading: true } as Status)
  const [open, setOpen] = useState(false)

  function updateStatus(newStatus: Status) {
    setStatus((old) => ({ ...old, ...newStatus }))
  }

  function closeDialog() {
    setOpen(false)
  }

  const updateView = useCallback((json: any) => {
    const newStatus = {} as Status

    if (json?.items?.length) {
      const item = json.items.shift()
      const message = decryption({
        dialogId,
        data: item.message,
      })

      newStatus.loading = false
      newStatus.dateSent = moment(new Date(item.date_sent * 1000)).format('MMMM Do YYYY, h:mm:ss A')
      newStatus.message = message

      if (item.attachments.length) {
        for (const attachment of item.attachments) {
          const path = `quickblox/contents/${userId}/${attachment.id}`
          if (attachment.type === 'image') {
            newStatus.loading = true

            fetchImage(path, attachment.contentType, dialogId)
              .then((image) => {
                updateStatus({
                  loading: false,
                  image,
                })
              })
          } else if (attachment.type === 'video') {
            newStatus.loading = true

            fetchVideo(path)
              .then((url) => {
                updateStatus({
                  loading: false,
                  video: {
                    url,
                    width: attachment.width,
                    height: attachment.height,
                  },
                })
              })
          }
        }
      }

      if (item?.audio_path) {
        newStatus.loading = true

        fetchAudio(item.audio_path, dialogId)
          .then((audio) => updateStatus({
            loading: false,
            audio,
          }))
      }
    } else {
      newStatus.loading = false
      newStatus.error = 'Message Not Found'
    }

    if (!newStatus.error && setDisabledDeleteButton) {
      setDisabledDeleteButton(false)
    }

    updateStatus(newStatus)
  }, [userId, dialogId, setDisabledDeleteButton])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAdmin({
          path: `quickblox/messages/${userId}/${dialogId}/${messageId}`,
          method: 'GET',
        })
        const json = await response.json()
          .then(updateView)
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
      }
    }
    fetchData()
  }, [userId, dialogId, messageId, updateView])

  useEffect(() => (
    () => {
      if (status.video?.url) {
        URL.revokeObjectURL(status.video.url)
      }
    }
  ), [status])

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h4">
            QB Message Details
          </Typography>

          {status.loading
            && <CircularProgress />}

          {status.error
            && (
            <CardContent>
              <Typography variant="body2">
                {status.error}
              </Typography>
            </CardContent>
            )}
        </CardContent>

        {!status.loading && !status.error
          && (
          <>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Message
              </Typography>
              <Typography variant="body2" sx={{ whiteSpace: 'pre' }}>
                {status.message}
              </Typography>
            </CardContent>

            {status.image
              && (
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Image
                </Typography>
                <CardMedia
                  component="img"
                  src={status.image}
                  sx={{
                    width: 'auto',
                    height: '25vw',
                  }}
                  onClick={() => setOpen(true)}
                />
              </CardContent>
              )}

            {status.audio
              && (
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Audio
                </Typography>
                <CardMedia
                  controls
                  component="audio"
                  src={status.audio}
                />
              </CardContent>
              )}

            {(status.video != null)
              && (
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Video
                </Typography>
                <CardMedia
                  controls
                  component="video"
                  src={status.video.url}
                  sx={{
                    maxWidth: '100%',
                    width: `${status.video.width / 2}px`,
                    height: `${status.video.height / 2}px`,
                  }}
                />
              </CardContent>
              )}

            <CardContent>
              <Typography variant="h5" gutterBottom>
                Date Sent
              </Typography>
              <Typography variant="body2">
                {status.dateSent}
              </Typography>
            </CardContent>
          </>
          )}
      </Card>

      {status.image
        && (
        <Dialog
          open={open}
          onClose={closeDialog}
        >
          <DialogContent>
            <img alt="message" src={status.image} />
          </DialogContent>
        </Dialog>
        )}
    </>
  )
}

async function fetchImage(path: string, contentType: string, dialogId: string) {
  const response = await fetchAdmin({
    path,
    method: 'GET',
  })
  const data = await response.arrayBuffer()
  return `data:${contentType};base64,${decryption({ dialogId, data })}`
}

async function fetchAudio(path: string, dialogId: string) {
  const response = await fetch(
    buildStorageApiUrl(path),
    {
      method: 'GET',
    },
  )
  const data = await response.arrayBuffer()

  return `data:audio/x-m4a;base64,${decryption({ dialogId, data })}`
}

async function fetchVideo(path: string) {
  const response = await fetchAdmin({
    path,
    method: 'GET',
  })
  const blob = new Blob([await response.arrayBuffer()])

  return URL.createObjectURL(blob)
}

export default QuickbloxMessageDetailsCard
