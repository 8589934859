import React from 'react'
import { WidgetTagsContext } from '../../config'

export const expectedWidgetUpdateUserTag = 'update_user'
export const expectedWidgetDeleteUserTag = 'delete_user'
export const expectedWidgetUpdateDialogTag = 'update_dialog'
export const expectedWidgetDeleteDialogTag = 'delete_dialog'
export const expectedWidgetUpdateMomentTag = 'update_moment'
export const expectedWidgetDeleteMomentTag = 'delete_moment'
export const expectedWidgetUpdateReportTag = 'update_report'
export const expectedWidgetDeleteReportTag = 'delete_report'
export const expectedWidgetUpdateMaintenanceTag = 'update_maintenance'
export const expectedWidgetDeleteMaintenanceTag = 'delete_maintenance'
export const expectedWidgetUpdateUploadImageTag = 'update_images'
export const expectedWidgetUpdateAnalyticsTag = 'update_analytics'
export const expectedWidgetDeleteAnalyticsTag = 'delete_analytics'
export const expectedWidgetUpdateCampaignTag = 'update_campaign'
export const expectedWidgetDeleteCampaignTag = 'delete_campaign'
export const expectedWidgetUpdateAdminTag = 'update_admin'
export const expectedWidgetDeleteAdminTag = 'delete_admin'
export const expectedWidgetUpdateContentModerationTag = 'update_content_moderation'
export const expectedWidgetDeleteContentModerationTag = 'delete_content_moderation'
export const expectedWidgetUpdateDebugTag = 'update_debug'
export const expectedWidgetDeleteDebugTag = 'delete_debug'
interface Props {
  expectedWidgetTag: string
  children: React.ReactNode
}

function WidgetTagsAuth(props: Props) {
  const { expectedWidgetTag, children } = props

  return (
    <WidgetTagsContext.Consumer>
      {(accessibleWidgetTags) => {
        if (accessibleWidgetTags.includes(expectedWidgetTag)) {
          return (
            <>
              {children}
            </>
          )
        }
        return null
      }}
    </WidgetTagsContext.Consumer>
  )
}

export default WidgetTagsAuth
