import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function CreateAdminUserModal(props: Props) {
  const { open, handleClose } = props

  const [values, setValues] = useState({
    userName: '',
    password: '',
    userRole: '',
    showPassword: false,
    incorrectLogin: false,
    miscErr: false,
  })
  const [selectRoles, setSelectRoles] = useState({
    rows: [] as string[],
  })

  const handleChange = (prop: string): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleSelectorChange = (event: SelectChangeEvent) => {
    setValues({ ...values, userRole: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      createUser()
    }
  }

  const createUser = async () => {
    try {
      await fetchAdmin({
        path: 'adminUser',
        method: 'POST',
        body: {
          userName: values.userName,
          password: values.password,
          role: values.userRole,
        },
      })
    } catch (error) {
      console.log(error) // eslint-disable-line no-console
    }
    handleClose(true)
  }

  useEffect(() => {
    fetchAdmin({
      path: 'adminUserRoles',
      method: 'GET',
      queries: {
        limit: 100,
        offset: 0,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        const userRoles = [] as string[]
        for (const userRole of json.rows) {
          userRoles.push(userRole.role)
        }
        setSelectRoles({ rows: userRoles })
      })
      .catch(() => {})

    return () => {
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>
        Create new user.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-userName">Username</InputLabel>
            <Input
              id="standard-adornment-userName"
              type="text"
              value={values.userName}
              onChange={handleChange('userName')}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              id="standard-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              onKeyDown={handleKeyDown}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
          )}
            />
            { values.incorrectLogin && <FormHelperText error id="standard-incorrect-login-helper-text">Username or password is incorrect</FormHelperText> }
            { values.miscErr && <FormHelperText error id="standard-err-helper-text">Please try again later</FormHelperText> }
          </FormControl>
          <FormControl variant="standard" sx={{ mx: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.userRole}
              label="Role"
              onChange={handleSelectorChange}
            >
              {selectRoles.rows.map((row) => (
                <MenuItem value={row}>{row}</MenuItem>
              ))}
            </Select>
          </FormControl>

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={createUser} color="info">Create</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateAdminUserModal
