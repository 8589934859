import Delete from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { useState } from 'react'
import { COLUMN_WIDTH } from '../../constants'
import DataGrid, { DateCell } from '../common/DataGrid'
import DeleteMomentCommentModal from './DeleteMomentCommentModal'
import WidgetTagsAuth, { expectedWidgetUpdateMomentTag, expectedWidgetDeleteMomentTag } from '../common/WidgetTagsAuth'

interface Props {
  identifier: string
}

function MomentCommentGrid(props: Props) {
  const { identifier } = props
  const [refresh, setRefresh] = useState(false)

  const [deleteMomentComment, setDeleteMomentComment] = useState({
    id: 0,
    type: '',
    comment: '',
    open: false,
  })

  const handleOpenDeleteMomentCommentModal = (id: number, type: string, comment: string) => {
    setDeleteMomentComment({
      id,
      type,
      comment,
      open: true,
    })
  }

  const handleCloseDeleteMomentCommentModal = (actionTriggered: boolean) => {
    setDeleteMomentComment({ ...deleteMomentComment, open: false })
    if (actionTriggered) {
      setRefresh(!refresh) // NOTE: This is a hack to refresh the grid
    }
  }

  return (
    <>
      <DataGrid
        show
        refresh={refresh}
        searchColumns={[
          { label: 'ID', field: 'id' },
          { label: 'User ID', field: 'quickbloxUserId' },
        ]}
        path={`moments/${identifier}/comments`}
        columns={[
          ...columns,
          {
            field: ' ',
            headerName: '',
            width: COLUMN_WIDTH.MORE_DETAILS,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateMomentTag || expectedWidgetDeleteMomentTag}>
                <IconButton
                  onClick={() => handleOpenDeleteMomentCommentModal(params.row.id, params.row.type, params.row.message)}
                  color="error"
                >
                  <Delete />
                </IconButton>
              </WidgetTagsAuth>
            ),
          },
        ]}
        autoHeight
      />
      <DeleteMomentCommentModal
        identifier={identifier}
        commentId={deleteMomentComment.id}
        commentType={deleteMomentComment.type}
        comment={deleteMomentComment.comment}
        open={deleteMomentComment.open}
        handleClose={handleCloseDeleteMomentCommentModal}
      />
    </>
  )
}

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'quickbloxUserId',
    headerName: 'User ID',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    filterable: false,
    sortable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 80,
    filterable: false,
    sortable: false,
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 500,
    filterable: false,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
]

export default MomentCommentGrid
