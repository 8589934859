import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, {
  DateCell,
  buildDetailsButton,
  filterDateTimeOps,
  filterNumericOps,
  filterStringOps,
} from '../common/DataGrid'

interface Props {
  show: boolean
  refresh: boolean
}

function GiftCodeGrid(props: Props) {
  const { show, refresh } = props

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="campaign/gift-codes"
      columns={columns}
      initialSortItem={{ field: 'id', sort: 'desc' }}
      onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_DETAILS, row.row)}
      showTimeZoneSelect
    />
  )
}

function campaignName(campaignId: number) {
  let v = ''
  switch (campaignId) {
    case 1:
      v = 'Referral Program for Ambassadors'
      break
    case 2:
      v = 'Referral Program'
      break
    case 3:
      v = 'Month Moment Marathon'
      break
    case 4:
      v = 'Chat Master'
      break
    case 5:
      v = '1 Week Moment Marathon'
      break
    case 6:
      v = 'Month Moment Marathon'
      break
    case 12:
      v = 'Weekly Like Moment'
      break
    default:
      break
  }
  return v
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_DETAILS })

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    filterOperators: filterNumericOps,
  },
  {
    field: 'quickbloxUserId',
    headerName: 'User ID',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    filterOperators: filterNumericOps,
  },
  {
    field: 'campaignId',
    headerName: 'CampaignId',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    filterable: false,
    valueGetter: (params) => params.row.entry?.campaignId,
  },
  {
    field: 'campaignName',
    headerName: 'Name',
    width: COLUMN_WIDTH.DETAILS,
    sortable: false,
    filterable: false,
    valueGetter: (params) => campaignName(params.row.entry?.campaignId),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    filterable: false,
  },
  {
    field: 'giftCode',
    headerName: 'GiftCode',
    width: COLUMN_WIDTH.DETAILS,
    filterOperators: filterStringOps,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'detailsButton',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

export default GiftCodeGrid
