import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetDeleteReportTag } from '../common/WidgetTagsAuth'

interface Props {
  reportId: number
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function DeleteMomentReportModal(props: Props) {
  const { reportId, open, handleClose } = props
  const [disableButtons, setDisableButtons] = useState(false)

  const deleteReport = async () => {
    setDisableButtons(true)

    await fetchAdmin({
      path: `moment-reports/${reportId}`,
      method: 'DELETE',
    })
    handleClose(true)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteReportTag}>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>
          {`Delete report #${reportId}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once removed, you can&apos;t undo this operation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={disableButtons} onClick={deleteReport} color="error">Remove</Button>
          <Button disabled={disableButtons} onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default DeleteMomentReportModal
