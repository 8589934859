import { useParams } from 'react-router-dom'
import DialogDetails from './DialogDetails'

function DialogDetailsRouter() {
  const { quickbloxDialogId } = useParams()

  return (
    <DialogDetails
      quickbloxDialogId={quickbloxDialogId as string}
    />
  )
}

export default DialogDetailsRouter
