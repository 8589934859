import {
  Button,
  Stack,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useState, useEffect } from 'react'
import AdminUserDetails from './AdminUserDetails'
import eventBus from '../../services/eventBus'
import AdminUserGrid from './AdminUserGrid'
import CreateAdminUserModal from './CreateAdminUserModal'
import { EVENT } from '../../constants'

function AdminUsers() {
  const [showCreateAdminUserModal, setShowCreateAdminUserModal] = useState(false)
  const [userName, setUserName] = useState('')
  const [role, setRole] = useState('')
  const [refresh, setRefresh] = useState(false) // This state is toggled to rerender the user grid.

  const handleCloseCreateAdminUserModal = (actionTriggered: boolean) => {
    setShowCreateAdminUserModal(false)
    setRefresh((r) => !r)
  }

  const handleOpenCreateAdminUserModal = () => {
    setShowCreateAdminUserModal(true)
  }

  const handleExitUserDetails = () => {
    setUserName('')
    setRole('')
    setRefresh((r) => !r)
  }

  useEffect(() => {
    function showAdminUserHelper(e: CustomEvent) {
      setUserName(e.detail.userName)
      setRole(e.detail.role)
    }
    eventBus.on(EVENT.SHOW_ADMIN_USER_DETAILS, showAdminUserHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_ADMIN_USER_DETAILS, showAdminUserHelper)
    }
  }, [])

  return (
    <>
      <Stack direction="row" spacing={2} m={1}>
        {
          userName === '' && (
            <Button
              onClick={handleOpenCreateAdminUserModal}
              variant="outlined"
              color="info"
              startIcon={<AddIcon />}
            >
              Create New
            </Button>
          )
        }
      </Stack>
      <AdminUserGrid show={!userName} refresh={refresh} />
      { userName !== ''
      && <AdminUserDetails userName={userName} role={role} backText="AdminUsers" exitUserDetails={handleExitUserDetails} />}
      <CreateAdminUserModal
        open={showCreateAdminUserModal}
        handleClose={handleCloseCreateAdminUserModal}
      />
    </>
  )
}

export default AdminUsers
