import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { fetchAdmin } from '../../services/fetch'
import WidgetTagsAuth, { expectedWidgetUpdateUserTag } from '../common/WidgetTagsAuth'

interface Props {
  userId: number
  name: string
  isStaff: boolean
  open: boolean
  handleClose: (actionTriggered: boolean, newIsStaff?: boolean) => void
}

function SwitchingStaffModal(props: Props) {
  const {
    userId, name, open, handleClose,
  } = props
  let { isStaff } = props

  const switchStaff = async () => {
    try {
      isStaff = !isStaff
      await fetchAdmin({
        path: `users/${userId}`,
        method: 'PUT',
        body: ({ isStaff }),
      })
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    }
    handleClose(true, isStaff)
  }

  return (
    <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateUserTag}>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>
          { isStaff ? 'Remove the staff badge from' : 'Add the staff badge add'}
          {`"${name}"?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText />
        </DialogContent>
        <DialogActions>
          { isStaff
            ? <Button onClick={switchStaff} color="error">Remove</Button>
            : <Button onClick={switchStaff} color="info">Add</Button>}
          <Button onClick={() => handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </WidgetTagsAuth>
  )
}

export default SwitchingStaffModal
