import { useParams } from 'react-router-dom'
import WeeklyLikeMomentDetails from './WeeklyLikeMomentDetails'

function WeeklyLikeMomentDetailsRouter() {
  const { weeklyLikeMomentId } = useParams()

  return (
    <WeeklyLikeMomentDetails
      weeklyLikeMomentId={weeklyLikeMomentId as string}
    />
  )
}

export default WeeklyLikeMomentDetailsRouter
