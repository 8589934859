import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH, COUNTRIES, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, {
  DateCell,
  buildDetailsButton, filterDateTimeOps, filterNumericOps, filterStringOps, fliterSingleSelectOps,
} from '../common/DataGrid'

interface Props {
  show: boolean
  refresh: boolean
}

function AdminUserGrid(props: Props) {
  const { show, refresh } = props
  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="adminUsers"
      columns={columns}
      disableToolbar
      onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_ADMIN_USER_DETAILS, row.row)}
      setCustomRowClassName={(params) => {
        if (params.row.deletedAt) return 'row-color-error'
        return ''
      }}
      setCustomRowDescriptions={[
        { rowClassName: 'row-color-error', description: 'Deleted' },
      ]}
      showTimeZoneSelect
    />
  )
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_ADMIN_USER_DETAILS })

const columns: GridColumns = [
  {
    field: 'userName',
    headerName: 'UserName',
    width: 150,
    filterOperators: filterStringOps,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 130,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: ' ',
    headerName: '',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

export default AdminUserGrid
