import { GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { COLUMN_WIDTH } from '../../constants'
import DataGrid, { DateCell, buildDetailsButton } from '../common/DataGrid'
import JobSearchToolbar, { initToolbarQueries } from './JobSearchToolbar'

interface Props {
  show: boolean,
  refresh: boolean,
  goDetails?: (params: GridRowParams | GridRenderCellParams) => void,
}

function JobGrid(props: Props) {
  const { show, refresh, goDetails } = props
  const navigate = useNavigate()
  const onRowDoubleClick = (params: GridRowParams | GridRenderCellParams) => {
    if (goDetails) {
      goDetails(params)
    } else {
      navigate(`/jobs/${params.row.id}`)
    }
  }

  addGoDetailsButton(onRowDoubleClick)

  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="jobs"
      columns={columns}
      initialSortItem={{ field: 'id', sort: 'desc' }}
      onRowDoubleClick={onRowDoubleClick}
      toolbarComponent={JobSearchToolbar}
      initToolbarQueries={initToolbarQueries}
    />
  )
}

function addGoDetailsButton(goDetails: (params: GridRenderCellParams) => void) {
  const col = columns.pop()

  if (col) {
    const Button = buildDetailsButton<GridRenderCellParams>({
      callback: (params) => {
        goDetails(params)
      },
    })

    col.renderCell = (params) => (
      <Button data={params} />
    )

    columns.push(col)
  }
}

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: COLUMN_WIDTH.ID,
    sortable: false,
    filterable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: COLUMN_WIDTH.CATEGORY,
    sortable: false,
    filterable: false,
  },
  {
    field: 'state',
    headerName: 'State',
    width: COLUMN_WIDTH.USERNAME,
    sortable: false,
    filterable: false,
  },
  {
    field: 'quickbloxUserId',
    headerName: 'QuickbloxUserId',
    width: COLUMN_WIDTH.LONG_QUICKBLOX_USER_ID,
    sortable: false,
    filterable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    sortable: false,
    filterable: false,
    renderCell: (params) => <DateCell date={params.value} />,
  },
  {
    field: 'processedAt',
    headerName: 'Processed At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    sortable: false,
    filterable: false,
    renderCell: (params) => <DateCell date={params.value} />,
  },
  {
    field: 'finishedAt',
    headerName: 'Finished At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    sortable: false,
    filterable: false,
    renderCell: (params) => <DateCell date={params.value} />,
  },
  {
    field: ' ',
    headerName: '',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    disableColumnMenu: true,
  },
]

export default JobGrid
