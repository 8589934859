import { useNavigate, useParams } from 'react-router-dom'
import UserDetails from './UserDetails'

function UserDetailsRouter() {
  const { quickbloxUserId } = useParams()
  const navigate = useNavigate()
  const exit = () => {
    navigate(-1)
  }

  return (
    <UserDetails
      quickbloxUserId={parseInt(quickbloxUserId as string)}
      backText="back"
      exitUserDetails={exit}
    />
  )
}

export default UserDetailsRouter
