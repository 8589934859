import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAdmin } from '../../services/fetch';
import { QUERY_KEYS } from './keys';

async function getTextFilters() {
  const response = await fetchAdmin({ path: 'textFilters', method: 'GET' });
  return response.json();
}

export function useGetTextFilters() {
  return useQuery({
    queryKey: [QUERY_KEYS.TEXTFILTERS],
    queryFn: getTextFilters,
  });
}

async function deleteTextFilters(ids: number[]) {
  return fetchAdmin({ path: 'textFilters', method: 'DELETE', body: { ids: ids.join(',') } });
}

export function useDeleteTextFilters() {
  const queryClient = useQueryClient();
  return useMutation(deleteTextFilters, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.TEXTFILTERS]);
    },
  });
}

async function addTextFilter(textFilters: string) {
  return fetchAdmin({
    path: 'textFilters',
    method: 'POST',
    body: { textFilters },
  });
}

export function useAddTextFilter() {
  const queryClient = useQueryClient();
  return useMutation(addTextFilter, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.TEXTFILTERS]);
    },
  });
}
