import {
  Card, CardContent, CircularProgress, Typography,
} from '@mui/material'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { fetchAdmin } from '../../services/fetch'
import { decryption } from '../../services/encryption'
import UserProfileLink from '../user/UserProfileLink'

interface Props {
  userId: number
  dialogId: string
  setDisabledDeleteButton?: (state: boolean) => void
}

interface Status {
  id: string
  creatorId: number
  loading?: boolean
  name?: string
  lastMessage?: string
  dateSent?: string
  type?: string
  createdAt?: string
  updatedAt?: string
  error?: string
}

function QuickbloxDialogDetailsCard(props: Props) {
  const { userId, dialogId, setDisabledDeleteButton } = props
  const [status, setStatus] = useState({ loading: true } as Status)

  const updateStatus = (newStatus: Status) => {
    setStatus((old) => ({ ...old, ...newStatus }))
  }

  function formatDate(date: Date): string {
    return date.toLocaleString('ja-JP', {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
    }).replace(/\//g, '/');
  }

  const updateView = useCallback((json: any) => {
    const newStatus = {} as Status
    if (json?.items?.length) {
      const item = json.items.shift()
      newStatus.id = item._id
      newStatus.loading = false
      newStatus.name = item.name
      newStatus.dateSent = moment(item.last_message_date_sent * 1000).format('MMMM Do YYYY, h:mm:ss A');
      newStatus.lastMessage = item.last_message
      newStatus.creatorId = item.user_id || userId
      newStatus.createdAt = moment(item.created_at).format('MMMM Do YYYY, h:mm:ss A');
      newStatus.updatedAt = moment(item.updated_at).format('MMMM Do YYYY, h:mm:ss A');
    } else {
      newStatus.loading = false
      newStatus.error = 'Dialog Not Found'
    }

    if (!newStatus.error && setDisabledDeleteButton) {
      setDisabledDeleteButton(false)
    }

    updateStatus(newStatus)
  }, [userId, setDisabledDeleteButton])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAdmin({
          path: `quickblox/dialogs/${userId}/${dialogId}`,
          method: 'GET',
        })
        const json = await response.json()
        updateView(json)
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
      }
    }
    fetchData()
  }, [userId, dialogId, updateView])

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">
          Dialog Details
        </Typography>

        {status.loading && <CircularProgress />}

        {status.error && (
          <Typography variant="body2">{status.error}</Typography>
        )}

        {!status.loading && !status.error && (
          <>
            <CardContent>
              <Typography variant="h5">Dialog Name</Typography>
              <Typography variant="body2">{status.name}</Typography>
            </CardContent>

            <CardContent>
              <Typography variant="h5">Creator ID</Typography>
              <Typography variant="body2">
                <UserProfileLink userId={status.creatorId} />
              </Typography>
            </CardContent>

            <CardContent>
              <Typography variant="h5">Last Message</Typography>
              <Typography variant="body2">{status.lastMessage ? decryption({ dialogId: status.id, data: status.lastMessage }) : 'No message available'}</Typography>
            </CardContent>

            <CardContent>
              <Typography variant="h5">Last Message Date Sent</Typography>
              <Typography variant="body2">{status.dateSent}</Typography>
            </CardContent>

            <CardContent>
              <Typography variant="h5">Created At</Typography>
              <Typography variant="body2">{status.createdAt}</Typography>
            </CardContent>

            <CardContent>
              <Typography variant="h5">Updated At</Typography>
              <Typography variant="body2">{status.updatedAt}</Typography>
            </CardContent>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default QuickbloxDialogDetailsCard
