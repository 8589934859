import React, { useState, ChangeEvent } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  open: boolean
  handleClose: (actionTriggered: boolean) => void
}

function CreateAdminUserRoleModal(props: Props) {
  const { open, handleClose } = props

  const [values, setValues] = useState({
    userRole: '',
    customRole: '',
  })

  const handleChange = (prop: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setValues({ ...values, userRole: event.target.value })
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      createUserRole()
    }
  }

  const createUserRole = async () => {
    const roleToSubmit = values.userRole === 'custom' ? values.customRole : values.userRole
    try {
      await fetchAdmin({
        path: 'adminUserRole',
        method: 'POST',
        body: {
          userRole: roleToSubmit,
        },
      })
      handleClose(true)
    } catch (error) {
      console.log(error) // eslint-disable-line no-console
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>Create new role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="standard-adornment-userRole">Role</InputLabel>
            <Select
              id="standard-adornment-userRole"
              value={values.userRole}
              onChange={handleSelectChange}
            >
              <MenuItem value="admin">admin</MenuItem>
              <MenuItem value="viewer">viewer</MenuItem>
              <MenuItem value="editor">editor</MenuItem>
              <MenuItem value="custom">custom</MenuItem>
            </Select>
          </FormControl>
          {values.userRole === 'custom' && (
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="standard-adornment-customRole">Custom Role</InputLabel>
              <Input
                id="standard-adornment-customRole"
                type="text"
                value={values.customRole}
                onChange={handleChange('customRole')}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={createUserRole} color="info">Create</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateAdminUserRoleModal
