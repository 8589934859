import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH } from '../../constants'
import DataGrid, { DateCell } from '../common/DataGrid'

interface Props {
  identifier: string
}

function MomentLikeGrid(props: Props) {
  const { identifier } = props

  return (
    <DataGrid
      show
      refresh
      path={`moments/${identifier}/likes`}
      columns={columns}
      autoHeight
    />
  )
}

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'quickbloxUserId',
    headerName: 'User ID',
    width: COLUMN_WIDTH.QUICKBLOX_USER_ID,
    filterable: false,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
]

export default MomentLikeGrid
