import { InfoOutlined } from '@mui/icons-material'
import {
  IconButton, ImageList, ImageListItem, ImageListItemBar, Pagination,
  Stack, Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchAdmin } from '../../services/fetch'
import { buildMomentThumbnailUrl, buildMomentVideoUrl } from '../../services/urlBuilder'
import { DateCell } from '../common/DataGrid'
import VideoCard from '../common/VideoCard'

interface Props {
  quickbloxUserId: number
}

interface UserMomentsInfo {
  totalRows: number
  rows: Moment[]
}

interface Moment {
  id: number
  quickbloxUserId: number
  identifier: string
  contents: {
    top: {
      mediaPublicId: string
      thumbnailPublicId: string
    }
    bottom: {
      mediaPublicId: string
      thumbnailPublicId: string
    }
  }
  visible: boolean
  commentNum: number | null
  likeNum: number | null
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

const cols = 7
const limit = cols * 3

function UserMomentList(props: Props) {
  const { quickbloxUserId } = props
  const [userMoments, setUserMoments] = useState<UserMomentsInfo>()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const offset = limit * page - limit

    fetchUserMoments({
      quickbloxUserId,
      setUserMoments,
      offset,
    })
  }, [quickbloxUserId, page])

  if (userMoments === undefined) {
    return null
  }

  return userMoments.totalRows !== 0
    ? (
      <>
        <Pager
          totalRows={userMoments.totalRows}
          page={page}
          setPage={setPage}
        />

        <ImageList cols={cols}>
          {userMoments.rows.map((row) => (
            <ImageListItem key={row.id}>
              <VideoCard
                thumbnailUrl={buildMomentThumbnailUrl(row.contents.top.thumbnailPublicId)}
                videoUrl={buildMomentVideoUrl(row.contents.top.mediaPublicId)}
              />

              <ImageListItemBar
                title={row.id}
                subtitle={<DateCell date={row.createdAt} />}
                actionIcon={(
                  <Link to={`/moments/${row.identifier}`} target="_blank">
                    <IconButton size="small">
                      <InfoOutlined />
                    </IconButton>
                  </Link>
                )}
                position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Pager
          totalRows={userMoments.totalRows}
          page={page}
          setPage={setPage}
        />
      </>
    )
    : (
      <Typography align="center">no moments</Typography>
    )
}

function Pager({
  totalRows,
  page,
  setPage,
}: {
  totalRows: number
  page: number
  setPage: (p: number) => void
}) {
  const count = Math.ceil(totalRows / limit)

  return (
    <Stack direction="row" justifyContent="center">
      <Pagination
        page={page}
        onChange={(ev, p) => setPage(p)}
        count={count}
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  )
}

function fetchUserMoments({
  quickbloxUserId,
  setUserMoments,
  offset,
}: {
  quickbloxUserId: number
  setUserMoments: (userMoments: UserMomentsInfo) => void
  offset: number
}): void {
  fetchAdmin({
    path: `moments/users/${quickbloxUserId}`,
    method: 'GET',
    queries: {
      limit,
      offset,
      sortField: 'id',
      dir: 'desc',
      showDeleted: true,
    },
  })
    .then((res) => res.json())
    .then((json) => setUserMoments(json))
}

export default UserMomentList
