import { NavigateBefore } from '@mui/icons-material'
import {
  Alert,
  AlertColor,
  Button,
  Card,
  CardActions,
  CardContent,
  Slide,
  SlideProps,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { fetchAdmin } from '../../services/fetch'
import ConfirmModal from '../common/ConfirmModal'

const INIT_REWARD = {
  id: '',
  quickbloxUserId: '',
  rewardId: '',
  amount: '',
  senderAdminUserId: '',
  senderQuickbloxUserId: '',
  quickbloxDialogId: '',
  message: '',
  memo: '',
  result: '',
  confirmedAt: '',
  createdAt: '',
  updatedAt: '',
  deletedAt: '',
}

const INIT_ALERT = {
  severity: '',
  message: '',
  time: 0,
}

const FORM_COLUMNS = [
  {
    key: 'id',
  },
  {
    key: 'quickbloxUserId',
    description: 'quickblox user id of the target user to be rewarded',
    forCreate: true,
  },
  {
    key: 'rewardId',
  },
  {
    key: 'amount',
    description: 'reward amount. maximum value 50',
    forCreate: true,
  },
  {
    key: 'senderAdminUserId',
  },
  {
    key: 'senderQuickbloxUserId',
    description: 'quickblox user id to send message to',
  },
  {
    key: 'quickbloxDialogId',
    description: 'quickblox dialog id of message to be sent',
  },
  {
    key: 'message',
    description: 'the body of the message to be sent. the reward url is added to the end of the text',
    multiline: true,
    forCreate: true,
  },
  {
    key: 'memo',
    description: 'notes for administrators',
    multiline: true,
    forCreate: true,
    forUpdate: true,
  },
  {
    key: 'result',
    multiline: true,
  },
  {
    key: 'confirmedAt',
  },
  {
    key: 'createdAt',
  },
  {
    key: 'updatedAt',
  },
  {
    key: 'deletedAt',
  },
]

type RewardProps = Required<typeof INIT_REWARD>

type AlertProps = Required<typeof INIT_ALERT>

type FuncSetReward = (reward: Partial<RewardProps>) => void

type FuncSetAlert = (alert: Omit<AlertProps, 'time'>) => void

function RewardDetails() {
  const { id } = useParams()
  const [reward, setReward] = useReward()
  const [modalTrigger, setModalTrigger] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [alert, setAlert] = useAlert()
  const handleConfirm = () => {
    setModalTrigger((old) => !old)
  }
  const handleSubmit = useCallback(() => {
    createReward({
      reward,
      setReward,
      setAlert,
      setButtonDisabled,
    })
  }, [reward, setReward, setAlert, setButtonDisabled])
  const handleUpdate = useCallback(() => {
    updateReward({
      reward,
      setReward,
      setAlert,
      setButtonDisabled,
    })
  }, [reward, setReward, setAlert, setButtonDisabled])

  useEffect(() => {
    if (id) {
      findReward({
        id,
        setReward,
        setAlert,
      })
    }
  }, [id, setReward, setAlert])

  return (
    <>
      <CardAlert
        severity={alert.severity}
        message={alert.message}
        time={alert.time}
      />

      <ButtonBack />

      <Typography variant="h3">
        {
          id
            ? `Reward #${id}`
            : 'Reward'
        }
      </Typography>

      {
        (!id || reward.id)
          ? (
            <Card>
              <CardContent>
                <FormReward
                  reward={reward}
                  setReward={setReward}
                />
              </CardContent>

              <CardActions>
                {
                  !id
                    ? (
                      <Button
                        variant="contained"
                        color="info"
                        size="large"
                        disabled={buttonDisabled}
                        onClick={handleConfirm}
                      >
                        Send reward
                      </Button>
                    )
                    : null
                }

                {
                  reward.id
                    ? (
                      <Button
                        variant="contained"
                        color="info"
                        size="large"
                        disabled={buttonDisabled}
                        onClick={handleUpdate}
                      >
                        save
                      </Button>
                    )
                    : null
                }
              </CardActions>
            </Card>
          )
          : null
      }

      <ConfirmModal
        openTrigger={modalTrigger}
        title="Reward Send"
        message="Do you want to reward users?"
        onAccept={handleSubmit}
      />
    </>
  )
}

function useReward(): [RewardProps, FuncSetReward] {
  const [reward, setReward] = useState<RewardProps>(INIT_REWARD)
  const update = useCallback((newReward: Partial<RewardProps>) => {
    setReward((old) => ({ ...old, ...newReward }))
  }, [])

  return [reward, update]
}

function useAlert(): [AlertProps, FuncSetAlert] {
  const [alert, setAlert] = useState<AlertProps>(INIT_ALERT)
  const updateAlert: FuncSetAlert = useCallback(({ severity, message }) => {
    setAlert({
      severity,
      message,
      time: Date.now(),
    })
  }, [])

  return [alert, updateAlert]
}

async function findReward({
  id,
  setReward,
  setAlert,
}: {
  id: string
  setReward: FuncSetReward
  setAlert?: FuncSetAlert
}) {
  try {
    const res = await fetchAdmin({
      path: `campaign/rewards/${id}`,
      method: 'GET',
    })
    const json = await res.json()

    if (json) {
      const reward = Object.fromEntries(
        Object.entries(json).map(([k, v]) => [k, v ?? '']),
      )

      setReward(reward)
    } else {
      throw new Error('reward not found')
    }
  } catch (err) {
    if (setAlert) {
      setAlert({
        severity: 'error',
        message: String(err),
      })
    } else {
      throw err
    }
  }
}

async function createReward({
  reward,
  setReward,
  setAlert,
  setButtonDisabled,
}: {
  reward: RewardProps
  setReward: FuncSetReward
  setAlert: FuncSetAlert
  setButtonDisabled: (disabled: boolean) => void
}) {
  try {
    setButtonDisabled(true)

    const res = await fetchAdmin({
      path: 'campaign/rewards',
      method: 'POST',
      body: reward,
    })
    const json = await res.json()

    if (json.success) {
      setAlert({
        severity: 'success',
        message: 'success',
      })

      setReward(INIT_REWARD)
    } else {
      throw new Error(json.message || 'create reward failed')
    }
  } catch (err) {
    setAlert({
      severity: 'error',
      message: String(err),
    })
  } finally {
    setButtonDisabled(false)
  }
}

async function updateReward({
  reward,
  setReward,
  setAlert,
  setButtonDisabled,
}: {
  reward: RewardProps
  setReward: FuncSetReward
  setAlert: FuncSetAlert
  setButtonDisabled: (disabled: boolean) => void
}) {
  try {
    setButtonDisabled(true)

    await fetchAdmin({
      path: `campaign/rewards/${reward.id}`,
      method: 'PUT',
      body: reward,
    })

    await findReward({
      id: reward.id,
      setReward,
    })

    setAlert({
      severity: 'success',
      message: 'success',
    })
  } catch (err) {
    setAlert({
      severity: 'error',
      message: String(err),
    })
  } finally {
    setButtonDisabled(false)
  }
}

function CardAlert(props: AlertProps) {
  const { severity, message, time } = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (message) {
      setOpen(true)
    }
  }, [message, time, setOpen])

  if (!severity || !message) {
    return null
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Alert
        severity={severity as AlertColor}
      >
        {message}
      </Alert>

      <Snackbar
        open={open}
        message={message}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={SlideTransition}
      />
    </>
  )
}

function ButtonBack() {
  const location = useLocation()
  const navigate = useNavigate()
  const handleBack = () => {
    if (location.key === 'default') {
      navigate('/campaign/rewards')
    } else {
      navigate(-1)
    }
  }

  return (
    <Button
      startIcon={<NavigateBefore />}
      onClick={() => handleBack()}
    >
      back
    </Button>
  )
}

function FormReward({
  reward,
  setReward,
}: {
  reward: RewardProps
  setReward: FuncSetReward
}) {
  const isCreate = reward.id === ''

  return (
    <Stack spacing={2}>
      {FORM_COLUMNS.map((column) => {
        const {
          key, description, multiline, forCreate, forUpdate,
        } = column
        const readOnly = !isCreate && !forUpdate

        if (isCreate && !forCreate) {
          return null
        }

        return (
          <TextField
            key={key}
            label={key}
            helperText={description}
            value={Object.getOwnPropertyDescriptor(reward, key)?.value}
            multiline={multiline}
            minRows={multiline ? 3 : undefined}
            inputProps={{ readOnly }}
            variant={readOnly ? 'standard' : 'outlined'}
            onChange={(ev) => setReward({ [key]: ev.target.value })}
          />
        )
      })}
    </Stack>
  )
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />
}

export default RewardDetails
