import React, { useEffect, useState } from 'react'
import eventBus from '../../services/eventBus'
import MessageReportDetails from './MessageReportDetails'
import MessageReportGrid from './MessageReportGrid'

export const EVENT_SHOW_DETAILS = 'showMessageReportDetails'
export const EVENT_GO_BACK = 'goBackToMessageReports'

export interface MessageReportInfo {
  category: string
  createdAt: string
  deletedAt: string | null
  details: string
  id: number
  quickbloxDialogId: string
  quickbloxMessageId: string
  quickbloxMessage: string
  reporterQuickbloxUserId: number
  reportedQuickbloxUserId: number
  resolvedAt: string | null
  updatedAt: string
}

function MessageReports() {
  const [reportInfo, setReportInfo] = useState({} as MessageReportInfo)
  const [showGrid, setShowGrid] = useState(true)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    function showDetailsHelper(e: CustomEvent) {
      setShowGrid(false)
      setReportInfo(e.detail)
    }

    function goBackHelper() {
      setRefresh((r) => !r)
      setShowGrid(true)
    }

    eventBus.on(EVENT_SHOW_DETAILS, showDetailsHelper)
    eventBus.on(EVENT_GO_BACK, goBackHelper)

    return () => {
      eventBus.remove(EVENT_SHOW_DETAILS, showDetailsHelper)
      eventBus.remove(EVENT_GO_BACK, goBackHelper)
    }
  }, [])

  return (
    <>
      <MessageReportGrid
        refresh={refresh}
        show={showGrid}
      />
      {showGrid ? null : <MessageReportDetails reportInfo={reportInfo} />}
    </>
  )
}

export default MessageReports
