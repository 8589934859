import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../keys'
import { fetchAdmin } from '../../../services/fetch'
import { QueryParams } from '../params'

export function useGetUsers(params: QueryParams) {
  const queryParams = {
    ...params,
  }
  return useQuery({
    queryKey: [QUERY_KEYS.USERS, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: 'users',
        method: 'GET',
        queries: queryParams,
      })
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Error fetching users:', errorData) // eslint-disable-line no-console
      }
      return response.json()
    },
    keepPreviousData: true,
  })
}
