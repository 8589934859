import React, { useContext, useState } from 'react'
import {
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material'
import { WidgetTagsContext } from '../../config'
import { AppMenus, Menu } from '../../constants/appMenu'

interface SideMenuProps {
  menus?: Menu[];
}

interface MenuItemProps {
  menu: Menu;
  level: number;
  currentPath: string;
  children?: React.ReactNode;
}

export default function SideMenu({ menus = AppMenus }: SideMenuProps): React.ReactElement {
  const { pathname } = useLocation();
  const contextValue = useContext(WidgetTagsContext);
  const widgetTags = contextValue;

  const renderMenuItem = (menu: Menu, level = 0): React.ReactNode => {
    if (!widgetTags.includes(menu.widgetTag)) {
      return null;
    }

    return (
      <MenuItem
        key={menu.key}
        menu={menu}
        level={level}
        currentPath={pathname}
      >
        {menu.children?.map((childMenu) => renderMenuItem(childMenu, level + 1))}
      </MenuItem>
    );
  };

  return (
    <List component="nav" disablePadding>
      {menus.map((menu) => renderMenuItem(menu))}
    </List>
  );
}

function MenuItem({
  menu, level, currentPath, children,
}: MenuItemProps): React.ReactElement {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const Icon = menu.icon;

  const handleClick = (): void => {
    if (menu.children) {
      setOpen(!open);
    }
  };

  const isSelected = menu.path === currentPath;

  const listItemContent = (
    <>
      {Icon && <ListItemIcon><Icon /></ListItemIcon>}
      <ListItemText primary={menu.name} />
      {menu.children && (open ? <ExpandLess /> : <ExpandMore />)}
    </>
  );

  return (
    <>
      {menu.children ? (
        <ListItemButton
          onClick={handleClick}
          selected={isSelected}
          sx={{ pl: 2 * level + 2 }}
        >
          {listItemContent}
        </ListItemButton>
      ) : (
        <ListItemButton
          component={Link}
          to={menu.path || ''}
          selected={isSelected}
          sx={{ pl: 2 * level + 2 }}
        >
          {listItemContent}
        </ListItemButton>
      )}
      {menu.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
    </>
  );
}

export function getPageTitle(path: string): string {
  const findMenu = (menus: Menu[]): Menu | undefined => {
    for (const menu of menus) {
      if (menu.path === path) return menu;
      if (menu.children) {
        const found = findMenu(menu.children);
        if (found) return found;
      }
    }
    return undefined;
  };

  const menu = findMenu(AppMenus);
  return menu?.title || menu?.name || '';
}
