import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import GiftCodeGrid from './GiftCodeGrid'

export interface CampaignEntryInfo {
  id: number
  campaignId: number
  quickbloxUserId: number
  phone: string,
  state: number,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string
}

export interface CampaignCompletionInfo {
  id: number
  quickbloxUserId: number
  campaignEntryId: number
  tableName: string
  tableId: number
  tableData: object | null
  amount: number
  giftCodeId: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
}

export interface GiftCodeInfo {
  id: number
  quickbloxUserId: number
  campaignEntryId: number
  amount: number
  giftCode?: string
  result?: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  entry?: CampaignEntryInfo
  completions: CampaignCompletionInfo[]
}

function GiftCodes() {
  const navigate = useNavigate()

  useEffect(() => {
    function showDetailsHelper(e: CustomEvent) {
      navigate(`/campaign/giftCodes/${e.detail.id}`)
    }

    eventBus.on(EVENT.SHOW_DETAILS, showDetailsHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_DETAILS, showDetailsHelper)
    }
  }, [navigate])

  return (
    <>
      <GiftCodeGrid
        show
        refresh
      />
    </>
  )
}

export default GiftCodes
