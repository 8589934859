import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import { FormHelperText, IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { fetchAdmin } from '../../services/fetch'

interface Props {
  open: boolean
  handleClose: (actionTriggered: boolean) => void
  userName: string
}

function UpdateOwnPasswordModal(props: Props) {
  const { open, handleClose, userName } = props

  const [values, setValues] = useState({
    userName,
    currentPassword: '',
    showCurrentPassword: false,
    newPassword: '',
    showNewPassword: false,
    incorrectPassword: false,
    miscErr: false,
  })

  const handleClickShowCurrentPassword = () => {
    setValues({
      ...values,
      showCurrentPassword: !values.showCurrentPassword,
    })
  }

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    })
  }

  const handleMouseDownCurrentPassword: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
  }

  const handleMouseDownNewPassword: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
  }

  const handleChange = (prop: string): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      updateUser()
    }
  }

  const updateUser = async () => {
    try {
      const response = await fetchAdmin({
        path: 'adminUser/password',
        method: 'PUT',
        body: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
      })
      if (response.status === 200) {
        window.location.reload()
      } else {
        setValues({
          ...values,
          incorrectPassword: true,
          miscErr: false,
        })
      }
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>
        Update
        {' '}
        {values.userName}
        {' '}
        password.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-current-password">Current Password</InputLabel>
            <Input
              id="standard-adornment-current-password"
              type={values.showCurrentPassword ? 'text' : 'password'}
              value={values.currentPassword}
              onChange={handleChange('currentPassword')}
              onKeyDown={handleKeyDown}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownCurrentPassword}
                  >
                    {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            )}
            />
            {values.incorrectPassword && <FormHelperText error id="standard-incorrect-login-helper-text">Current Password is incorrect</FormHelperText>}
            {values.miscErr && <FormHelperText error id="standard-err-helper-text">Please try again later</FormHelperText>}
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-new-password">New Password</InputLabel>
            <Input
              id="standard-adornment-new-password"
              type={values.showNewPassword ? 'text' : 'password'}
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              onKeyDown={handleKeyDown}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                  >
                    {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            )}
            />
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={updateUser} color="info">Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateOwnPasswordModal
