import { useEffect, useState } from 'react'

import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import MaintenanceGrid from './MaintenanceGrid'
import MaintenanceDetails from './MaintenanceDetails'
import CreateMaintenanceTask from './CreateMaintenanceTask'
import eventBus from '../../services/eventBus'
import { EVENT } from '../../constants'
import WidgetTagsAuth, { expectedWidgetUpdateMaintenanceTag } from '../common/WidgetTagsAuth'

export interface MaintenanceInfo {
  createdAt: string
  deletedAt: string | null
  description: string
  endTime: string
  id: number
  startTime: string
  updatedAt: string
}

function Maintenance() {
  const [currDetails, setCurrDetails] = useState({} as MaintenanceInfo)
  const [currTab, setCurrTab] = useState<'grid' | 'details' | 'create'>('grid')
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    function showMaintenanceHelper(e: CustomEvent) {
      setCurrDetails(e.detail)
      setCurrTab('details')
    }
    eventBus.on(EVENT.SHOW_MAINTENANCE_DETAILS, showMaintenanceHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_MAINTENANCE_DETAILS, showMaintenanceHelper)
    }
  }, [])

  const goBackCallback = (refreshGrid: boolean) => {
    setCurrTab('grid')
    if (refreshGrid) {
      setRefresh((r) => !r)
    }
  }

  const createTaskCallback = () => {
    setCurrTab('create')
  }

  return (
    <Box sx={{
      height: '100%', display: 'flex', flexDirection: 'column', gap: 2,
    }}
    >
      {currTab === 'grid'
        ? (
          <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateMaintenanceTag}>
            <Button
              onClick={createTaskCallback}
              variant="outlined"
              color="info"
              sx={{ width: 155 }}
              startIcon={<AddIcon />}
            >
              Create New
            </Button>
          </WidgetTagsAuth>
        )
        : null}
      <MaintenanceGrid
        show={currTab === 'grid'}
        refresh={refresh}
      />
      {currTab === 'details' ? <MaintenanceDetails details={currDetails} goBack={goBackCallback} /> : null}
      {currTab === 'create' ? <CreateMaintenanceTask goBack={goBackCallback} /> : null}
    </Box>
  )
}

export default Maintenance
