import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
} from '@mui/material'
import CryptoJS from 'crypto-js'

const REDIRECT_URL = process.env.REACT_APP_ENV === 'production' ? 'https://z.ps' : 'https://s.z.ps'
const CAMPAIGN_SALT = process.env.REACT_APP_CAMPAIGN_SALT || ''
const CAMPAIGN_USER_ID = process.env.REACT_APP_CAMPAIGN_USER_ID ? parseInt(process.env.REACT_APP_CAMPAIGN_USER_ID) : 0

export function generateLaunchUrl({
  userId,
  campaignId,
}: {
  userId: number
  campaignId: number
}) : string {
  const timestamp = Date.now()
  const saltHash = generateSaltHash({ password: `${userId}-${campaignId}-${timestamp}` })
  return `${REDIRECT_URL}/campaign?userId=${userId}&campaignId=${campaignId}&timestamp=${timestamp}&h=${saltHash}`
}

function generateSaltHash({
  password,
}: {
  password: string
}): string {
  return CryptoJS.SHA256(`${password}-${CAMPAIGN_SALT}`).toString()
}

function Pages() {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>
              Referral Program for Ambassadors
            </TableCell>
            <TableCell>
              <Link href={generateLaunchUrl({ userId: CAMPAIGN_USER_ID, campaignId: 1 })} target="_blank">
                <img src="https://assets.zapshot.io/banner/banner-1.png" alt="" />
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>
              Referral Program
            </TableCell>
            <TableCell>
              <Link href={generateLaunchUrl({ userId: CAMPAIGN_USER_ID, campaignId: 2 })} target="_blank">
                <img src="https://assets.zapshot.io/banner/banner-2.png" alt="" />
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>
              Month Moment Marathon
            </TableCell>
            <TableCell>
              <Link href={generateLaunchUrl({ userId: CAMPAIGN_USER_ID, campaignId: 6 })} target="_blank">
                <img src="https://assets.zapshot.io/banner/banner-3.png" alt="" />
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>
              Chat Master
            </TableCell>
            <TableCell>
              <Link href={generateLaunchUrl({ userId: CAMPAIGN_USER_ID, campaignId: 4 })} target="_blank">
                <img src="https://assets.zapshot.io/banner/banner-4.png" alt="" />
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>
              1 Week Moment Marathon
            </TableCell>
            <TableCell>
              <Link href={generateLaunchUrl({ userId: CAMPAIGN_USER_ID, campaignId: 5 })} target="_blank">
                <img src="https://assets.zapshot.io/banner/banner-5.png" alt="" />
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Pages
