import { GridColumns } from '@mui/x-data-grid'
import { COLUMN_WIDTH, DIALOG_TYPES, EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import DataGrid, {
  DateCell,
  buildDetailsButton,
  filterDateTimeOps,
  filterStringOps,
  fliterSingleSelectOps,
} from '../common/DataGrid'

interface Props {
  show: boolean
  refresh: boolean
}

function FeaturedDialogGrid(props: Props) {
  const { show, refresh } = props
  return (
    <DataGrid
      show={show}
      refresh={refresh}
      path="dialogs/featured"
      columns={columns}
      onRowDoubleClick={(row) => eventBus.dispatch(EVENT.SHOW_DIALOG_DETAILS, row.row)}
      showDeletedCheckbox
      searchColumns={[
        { label: 'Name', field: 'name' },
        { label: 'Quickblox Dialog ID', field: 'quickbloxDialogId' },
      ]}
      setCustomRowClassName={(params) => {
        if (params.row.type === 'friend') return 'row-color-info'
        if (!params.row.isCreatorExists) return 'row-color-disabled'
        if (params.row.deletedAt) return 'row-color-error'
        return ''
      }}
      setCustomRowDescriptions={[
        { rowClassName: 'row-color-info', description: 'Friend' },
        { rowClassName: 'row-color-disabled', description: 'Creator not exists' },
        { rowClassName: 'row-color-error', description: 'Deleted' },
      ]}
      showTimeZoneSelect
    />
  )
}

const DetailsButton = buildDetailsButton<any>({ event: EVENT.SHOW_DIALOG_DETAILS })
const columns: GridColumns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    filterOperators: filterStringOps,
  },
  {
    field: 'id',
    headerName: 'Quickblox Dialog ID',
    width: 220,
    filterOperators: filterStringOps,
  },
  {
    field: 'type',
    headerName: 'Type',
    type: 'singleSelect',
    valueOptions: (Object.keys(DIALOG_TYPES) as (keyof typeof DIALOG_TYPES)[]).map((key) => ({
      value: key,
      label: DIALOG_TYPES[key],
    })),
    width: 100,
    valueGetter: ({ value }) => DIALOG_TYPES[value as keyof typeof DIALOG_TYPES],
    filterOperators: fliterSingleSelectOps,

  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: 'deletedAt',
    headerName: 'Deleted At',
    type: 'dateTime',
    width: COLUMN_WIDTH.DATE_TIME,
    filterOperators: filterDateTimeOps,
    renderCell: (params) => (
      <DateCell date={params.value} />
    ),
  },
  {
    field: ' ',
    headerName: '',
    width: COLUMN_WIDTH.MORE_DETAILS,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <DetailsButton data={params.row} />
    ),
  },
]

export default FeaturedDialogGrid
