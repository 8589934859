import {
  Chip,
  FormControl, Grid,
  InputLabel, MenuItem, Select, TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fetchAdmin } from '../../services/fetch'

const JobStates = [
  'completed',
  'failed',
  'active',
  'delayed',
  'waiting',
  'waiting-children',
  'paused',
  'repeat',
  'wait',
]

export const initToolbarQueries = {
  state: JobStates[0],
}

function JobSearchToolbar(props: any) {
  const { toolbarQueries, setToolbarQueries } = props
  const updateQueries = (newQueries: object) => {
    setToolbarQueries((old: object) => ({
      ...old,
      ...newQueries,
    }))
  }
  const [jobCounts, setJobCounts] = useState({} as {[key: string]: number})

  function changeText(event: React.ChangeEvent<HTMLInputElement>) {
    const input = event.target.value

    setTimeout(() => {
      if (event.target.value === input) {
        updateQueries({
          [event.target.name]: input,
        })
      }
    }, 1000)
  }

  useEffect(() => {
    fetchAdmin({
      path: 'jobs/count',
      method: 'GET',
    })
      .then((res) => res.json())
      .then((json) => setJobCounts(json))
      .catch(() => {})
  }, [toolbarQueries])

  return (
    <Grid container spacing={2} p={1}>
      <Grid item>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel id="jobQueue-state-select-label">state</InputLabel>
          <Select
            labelId="jobQueue-state-select-label"
            label="state"
            value={toolbarQueries.state}
            onChange={(event) => updateQueries({ state: event.target.value })}
          >
            {
              JobStates.map((state) => (
                <MenuItem key={state} value={state}>{state}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl>
          <TextField
            id="jobSearchToolbar-quickbloxUserId-text"
            label="quickbloxUserId"
            name="quickbloxUserId"
            defaultValue={toolbarQueries.quickbloxUserId}
            sx={{ width: 150 }}
            onChange={changeText}
          />
        </FormControl>
      </Grid>

      {
        Object.keys(jobCounts).length > 0
          && (
            <Grid item>
              <Grid container spacing={1}>
                {
                  Object.entries(jobCounts).map(([key, value]) => (
                    value
                      ? (
                        <Grid item key={key}>
                          <Chip label={`${key} ${value}`} clickable onClick={() => updateQueries({ state: key })} />
                        </Grid>
                      )
                      : null
                  ))
                }
              </Grid>
            </Grid>
          )
      }
    </Grid>
  )
}

export default JobSearchToolbar
