import { useState, useEffect } from 'react';
import {
  Button, Card, CardContent, Grid, Stack, Typography, Table, TableBody, TableCell, TableContainer, TableRow, CardActions,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAdmin } from '../../services/fetch';
import { DateText } from '../app/Timezone';
import UserDetails from '../user/UserDetails';
import { ChatInfo, EVENT_GO_BACK } from './Chats';
import eventBus from '../../services/eventBus';

interface Props{
  details?: ChatInfo;
}

interface Status {
  show: boolean;
  showUserDetails: boolean;
}

function ChatDetails(props: Props) {
  const { details } = props;
  const { identifier } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<Status>({
    show: false,
    showUserDetails: false,
  });
  const { show, showUserDetails } = status;

  const updateStatus = (newStatus: Partial<Status>) => {
    setStatus((old) => ({ ...old, ...newStatus }));
  };

  const exitUserDetails = () => {
    updateStatus({ show: true, showUserDetails: false });
  };

  function onExit() {
    if (details) {
      eventBus.dispatch(EVENT_GO_BACK)
    } else {
      navigate('/reports/chatMessage');
    }
  }

  useEffect(() => {
    async function fetchData() {
      const chatId = identifier || details?.id;
      try {
        const response = await fetchAdmin({
          path: `chat-messages-reports/${chatId}`,
          method: 'GET',
        });
        updateStatus({
          show: true,
        });
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }

    if (details) {
      fetchData();
    } else {
      updateStatus({ show: false });
    }
  }, [details, identifier])

  return show && details ? (
    <>
      <Button
        startIcon={<NavigateBeforeIcon />}
        onClick={() => onExit()}
      >
        Chat Messages
      </Button>

      <Stack direction="row">
        <Typography variant="h3" gutterBottom>
          {`Chat Message #${details.id}`}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ChatDetailsCard details={details} onExit={onExit} />
        </Grid>
        <Grid item xs={12}>
          <UserCard onClick={() => updateStatus({ show: false, showUserDetails: true })} />
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      {details != null && showUserDetails && (
        <UserDetails
          quickbloxUserId={details.senderId}
          backText="Chat Message"
          exitUserDetails={exitUserDetails}
        />
      )}
    </>
  );
}

interface ChatDetailsCardProps {
  details: ChatInfo;
  onExit: () => void;
}

function formatDate(date: Date): string {
  return date.toLocaleString('ja-JP', {
    year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
  }).replace(/\//g, '/');
}

function ChatDetailsCard({ details, onExit }: ChatDetailsCardProps) {
  const formattedDateSent = formatDate(new Date(details.dateSent * 1000));
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Message Details
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Sender ID</TableCell>
                <TableCell>{details.senderId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Sender Name</TableCell>
                <TableCell>{details.senderName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Dialog ID</TableCell>
                <TableCell>{details.chatDialogId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Message ID</TableCell>
                <TableCell>{details.idChatMessage}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Message</TableCell>
                <TableCell>{details.message}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Read Ids</TableCell>
                <TableCell>{details.readIds}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Delivered Ids</TableCell>
                <TableCell>{details.deliveredIds}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Date Sent</TableCell>
                <TableCell>{formattedDateSent}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Hook Urls</TableCell>
                <TableCell>{details.hookUrl}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Created At</TableCell>
                <TableCell><DateText date={details.createdAt} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Updated At</TableCell>
                <TableCell><DateText date={details.updatedAt} /></TableCell>
              </TableRow>
              {details.deletedAt && (
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Deleted At</TableCell>
                  <TableCell><DateText date={details.deletedAt} /></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

interface UserCardProps {
  onClick: () => void;
}

function UserCard({ onClick }: UserCardProps) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          User Details
        </Typography>
        <Typography variant="body2">
          Click here to view user details.
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={onClick}>User Details</Button>
      </CardActions>
    </Card>
  );
}

export default ChatDetails;
