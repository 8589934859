import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState, type PropsWithChildren, type ReactElement } from 'react';
import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { getStorage } from '../services/storage';
import { adminTheme, WidgetTagsContext } from '../config';
import { TimezoneProvider } from '../components/app/Timezone';

export function RootProvider({ children }: PropsWithChildren): ReactElement {
  const [queryClient] = useState(() => new QueryClient())
  // stable query client: https://tanstack.com/query/latest/docs/eslint/stable-query-client

  const accessibleWidgetTags = getStorage('accessibleWidgetTags', [])
  return (
    <WidgetTagsContext.Provider value={accessibleWidgetTags}>
      <ThemeProvider theme={adminTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <TimezoneProvider>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
          </TimezoneProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </WidgetTagsContext.Provider>
  );
}
