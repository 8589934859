import { Delete } from '@mui/icons-material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {
  Button, Card, CardContent, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { fetchAdmin } from '../../services/fetch'
import { buildMomentThumbnailUrl, buildMomentVideoUrl } from '../../services/urlBuilder'
import { DateText } from '../app/Timezone'
import ConfirmModal from '../common/ConfirmModal'
import VideoCard from '../common/VideoCard'
import MomentCommentGrid from './MomentCommentGrid'
import MomentLikeGrid from './MomentLikeGrid'
import { MomentInfo } from './Moments'
import WidgetTagsAuth, { expectedWidgetDeleteMomentTag } from '../common/WidgetTagsAuth'
import UserProfileLink from '../user/UserProfileLink'

interface Props {
  identifier: string
  backText: string
  exitDetails: (deleted: boolean) => void
}

function MomentDetails(props: Props) {
  const { identifier, backText, exitDetails } = props
  const [details, setDetails] = useState<MomentInfo | null>(null)
  const [openDeleteModalTrigger, setOpenDeleteModalTrigger] = useState(false)

  function deleteMoment() {
    fetchAdmin({
      path: `moments/${details?.identifier}`,
      method: 'DELETE',
    })
      .then(() => exitDetails(true))
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchAdmin({
          path: `moments/${identifier}`,
          method: 'GET',
        })
        const json = await response.json()
        setDetails(json.data)
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
        setDetails(null)
      }
    }

    if (identifier) {
      fetchData()
    } else {
      setDetails(null)
    }
  }, [identifier])

  if (!details) return null;

  return details
    && (
      <>
        {backText
            && (
            <Button
              startIcon={<NavigateBeforeIcon />}
              onClick={() => exitDetails(false)}
            >
              {backText}
            </Button>
            )}

        <Stack direction="row">
          <Typography variant="h3" gutterBottom>
            {`Moment #${details.id}`}
          </Typography>
        </Stack>
        <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteMomentTag}>
          <Stack direction="row" spacing={2}>
            <Button
              onClick={() => setOpenDeleteModalTrigger((t) => !t)}
              variant="outlined"
              color="error"
              startIcon={<Delete />}
              disabled={details.deletedAt !== null}
            >
              Delete
            </Button>
          </Stack>
        </WidgetTagsAuth>
        <Grid container spacing={2}>
          <Grid container item xs={4}>
            <MediaCard
              details={details}
            />
          </Grid>
          <Grid container item xs={8}>
            <MomentDetailsCard
              details={details}
            />
          </Grid>
          <Grid container item spacing={2} xs={12} direction="column">
            <Grid item>
              <Card>
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    Comment
                  </Typography>
                </CardContent>
                <MomentCommentGrid
                  identifier={details.identifier}
                />
              </Card>
            </Grid>

            <Grid item>
              <Card>
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    Like
                  </Typography>
                </CardContent>
                <MomentLikeGrid
                  identifier={details.identifier}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <ConfirmModal
          openTrigger={openDeleteModalTrigger}
          title={`Delete Moment #${details.id}?`}
          message="Once removed, you can&apos;t undo this operations."
          onAccept={deleteMoment}
        />
      </>
    )
}

interface MomentDetailsCardProps {
  details: MomentInfo
}

function MomentDetailsCard(props: MomentDetailsCardProps) {
  const { details } = props

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Details
        </Typography>

        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  QB User ID
                </TableCell>
                <TableCell>
                  <UserProfileLink userId={details.quickbloxUserId} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Name
                </TableCell>
                <TableCell>
                  {details.name}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Username
                </TableCell>
                <TableCell>
                  {details.userName}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Identifier
                </TableCell>
                <TableCell>
                  {details.identifier}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Contents Top MediaPublicId
                </TableCell>
                <TableCell>
                  {details?.contents?.top?.mediaPublicId}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Contents Top ThumbnailPublicId
                </TableCell>
                <TableCell>
                  {details?.contents?.top?.thumbnailPublicId}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Contents Bottom MediaPublicId
                </TableCell>
                <TableCell>
                  {details?.contents?.bottom?.mediaPublicId}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Contents Bottom ThumbnailPublicId
                </TableCell>
                <TableCell>
                  {details?.contents?.bottom?.thumbnailPublicId}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Comment
                </TableCell>
                <TableCell>
                  {details.commentNum}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Like
                </TableCell>
                <TableCell>
                  {details.likeNum}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Created At
                </TableCell>
                <TableCell>
                  <DateText date={details.createdAt} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Updated At
                </TableCell>
                <TableCell>
                  <DateText date={details.updatedAt} />
                </TableCell>
              </TableRow>

              {details.deletedAt
                && (
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Deleted At
                  </TableCell>
                  <TableCell>
                    <DateText date={details.deletedAt} />
                  </TableCell>
                </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

function MediaCard(props: MomentDetailsCardProps) {
  const { details } = props

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4" gutterBottom>
              Top
            </Typography>
            <VideoCard
              thumbnailUrl={buildMomentThumbnailUrl(details?.contents?.top?.thumbnailPublicId)}
              videoUrl={buildMomentVideoUrl(details?.contents?.top?.mediaPublicId)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4" gutterBottom>
              Bottom
            </Typography>
            {(details?.contents?.bottom?.thumbnailPublicId
            && (
            <VideoCard
              thumbnailUrl={buildMomentThumbnailUrl(details?.contents?.bottom?.thumbnailPublicId)}
              videoUrl={buildMomentVideoUrl(details?.contents?.bottom?.mediaPublicId)}
            />
            )
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MomentDetails
