import { GridSortDirection } from '@mui/x-data-grid'

export type UrlQueries = {
  [key: string]: any
}

export type GridApiUrlQueries = {
  limit?: number,
  offset?: number,
  sortField?: string,
  dir?: GridSortDirection // desc or asc
  filterOp?: string,
  filterField?: string,
  filterValue?: string,
  searchOp?: string,
  searchField?: string,
  searchValues?: string, // NOTE: accept multiple values split by comma as string
  showDeleted?: boolean,
  showResolved?: boolean,
  [key: string]: any,
}

export function buildApiUrl({
  path,
  queries = {},
}: {
  path: string,
  queries: UrlQueries | GridApiUrlQueries
}): string {
  return process.env.REACT_APP_BACKEND_ENDPOINT
    + path
    + (
      Object.keys(queries).length
        ? `?${Object.entries(queries).map((e) => `${e[0]}=${e[1]}`).join('&')}`
        : ''
    )
}

export function buildStorageApiUrl(path: string): string {
  return process.env.REACT_APP_STORAGE_API_ENDPOINT + path
}

export function buildMomentThumbnailUrl(id: string): string {
  return `${process.env.REACT_APP_MOMENT_THUMBNAIL_URL}${id}.jpg`
}

export function buildMomentVideoUrl(id: string): string {
  return `${process.env.REACT_APP_MOMENT_VIDEO_URL}${id}.mp4`
}
