import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fetchAdmin } from '../../services/fetch'

function Revisions() {
  const [backendGitRevision, setBackendGitRevision] = useState('unknown')
  const frontendGitRevision = process.env.REACT_APP_GIT_REVISION || 'not set'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAdmin({
          path: 'gitRevisions',
          method: 'GET',
        })
        const json = await response.json()
        setBackendGitRevision(json.gitRevision)
      } catch (err) {
        console.error(err) // eslint-disable-line no-console
      }
    }
    fetchData()
  }, [])

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Revisions
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Backend
                </TableCell>
                <TableCell>
                  {backendGitRevision}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Frontend
                </TableCell>
                <TableCell>
                  {frontendGitRevision}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
export default Revisions
